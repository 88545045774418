// constants
import browser from "@splitfire-agency/raiden-library/dist/constants/browser";

/**
 * Liste des headers à forward à l’API.
 */
const HEADER_LIST = [
  "x-real-ip",
  "x-forwarded-for",
  "x-forwarded-proto",
  "x-forwarded-host",
  "x-forwarded-port",
];

/**
 * Récupére la liste des headers
 * @param {object} param
 * @param {import("http").IncomingMessage} param.req
 * @returns {object}
 */
export default function nextGetForwardedHeaders({ req }) {
  const headers = {};

  if (!browser && req) {
    for (const header of HEADER_LIST) {
      if (header in req.headers) {
        headers[header] = req.headers[header];
      }
    }
  }

  return headers;
}
