// deps
import PropTypes from "prop-types";
import { Select } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { forwardRef } from "react";

// components
import SelectMultiple from "@splitfire-agency/raiden-library/dist/components/SelectMultiple";

const EnvironmentSelect = forwardRef(
  /**
   * @typedef {Object} Props
   * @property {boolean} [isMultiple]
   * @property {boolean} [isDisabled]
   * @property {string} [placeholder]
   * @property {boolean} [includeWildcard]
   * @property {string} [labelWildcard]
   * @property {object[]} [environments]
   *
   * @param {Props} props
   */
  function EnvironmentSelect(props, ref) {
    const {
      isMultiple = false,
      isDisabled = false,
      placeholder,
      includeWildcard,
      labelWildcard,
      environments,
      ...otherProps
    } = props;

    const intl = useIntl();

    if (environments?.length === 0) {
      return null;
    }

    return (
      <>
        {isMultiple ? (
          <SelectMultiple
            ref={ref}
            {...otherProps}
            listMaxHeight={500}
            isDisabled={isDisabled}
            placeholder={placeholder ?? "-"}
            label={function ({ count }) {
              return placeholder
                ? intl.formatMessage(
                    {
                      id: "raiden.admin.components.EnvironmentSelect.texts.label.withPlaceholder",
                      defaultMessage:
                        "{count, plural, =0 {{placeholder}} one {un environnement} other {# environnements}}",
                    },
                    { count, placeholder },
                  )
                : intl.formatMessage(
                    {
                      id: "raiden.admin.components.EnvironmentSelect.texts.label.withoutPlaceholder",
                      defaultMessage:
                        "{count, plural, =0 {aucun environnement} one {un environnement} other {# environnements}}",
                    },
                    { count },
                  );
            }}>
            {includeWildcard && (
              <option value="*">
                {labelWildcard ??
                  intl.formatMessage({
                    defaultMessage: "Tout environnement confondu",
                  })}
              </option>
            )}

            {(environments ?? []).map(function (environment) {
              return (
                <option key={environment.id} value={String(environment.id)}>
                  {environment.name}
                </option>
              );
            })}
          </SelectMultiple>
        ) : (
          <Select
            ref={ref}
            w={200}
            {...otherProps}
            isDisabled={isDisabled}
            placeholder={placeholder ?? "-"}>
            <>
              {includeWildcard && (
                <option key="*" value="*">
                  {labelWildcard ??
                    intl.formatMessage({
                      defaultMessage: "Tout environnement confondu",
                    })}
                </option>
              )}

              {(environments ?? []).map(function (environment) {
                return (
                  <option key={environment.id} value={environment.id}>
                    {environment.name}
                  </option>
                );
              })}
            </>
          </Select>
        )}
      </>
    );
  },
);

EnvironmentSelect.propTypes = {
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  includeWildcard: PropTypes.bool,
  labelWildcard: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.any),
};

export default EnvironmentSelect;
