import { createContext, useCallback, useMemo } from "react";
import useSWR from "swr";
import { usersCan, usersIsRoot } from "../../helpers/users";
import { browserApiFetcher } from "../../hooks/useApiFetcher";
import useLocale from "../../hooks/useLocale";
import generateApiUri from "../../libraries/utils/generateApiUri";

/**
 * @template [T=any]
 * @typedef {object} AuthAsContextValue
 * @property {boolean} loading
 * @property {boolean} logged
 * @property {T} [user]
 * @property {() => void} mutate
 * @property {(user: import("../../types/User").UserBase) => void} login
 * @property {() => void} logout
 * @property {(params: { authorizations: any, environmentId: any }) => boolean} can
 * @property {boolean} root
 */

/** @type {AuthAsContextValue} */
const DefaultValue = {
  loading: false,
  logged: false,
  user: undefined,
  mutate: () => {},
  login: () => {},
  logout: () => {},
  can: () => false,
  root: false,
};

/** @type {React.Context<AuthAsContextValue>} */
export const AuthAsContext = createContext(DefaultValue);

/**
 * @typedef {object} Props
 * @property {import("../../types/User").UserBase} initialUser
 * @property {import("react").ReactNode} children
 *
 * @param {Props} props
 */
function AuthAsProvider({ initialUser, children }) {
  const { locale } = useLocale();

  /** @type {import("swr").SWRResponse<import("../../types/Api/ApiResponse").ApiResponse<import("../../types/User").UserBase>>} */
  // @ts-ignore
  const { data, mutate, error } = useSWR(
    generateApiUri({
      id: "@auth.me",
      query: {
        fields: [
          "user.avatar",
          "user.customer.validations",
          "user.customer.global_subscriptions",
          "customer.default_address",
          "groups",
        ],
      },
    }),
    browserApiFetcher,
    {
      revalidateOnMount: false,
      revalidateOnFocus: true,
      refreshInterval: 0,
      dedupingInterval: 0,
      locale,
      logout() {
        mutate();
      },
      fallbackData: {
        data: initialUser,
      },
    },
  );

  const loading = !data && !error;

  const user = data?.data;

  const logged = user?.id !== undefined;

  const root = usersIsRoot({ user });

  /**
   * Déconnecte l’utilisateur.
   * À n’appeler qu’après avoir invoqué l’endpoint de
   *   déconnexion ou après avoir été déconnecté naturellement (par expiration).
   */
  const logout = useCallback(
    function () {
      mutate();
    },
    [mutate],
  );

  /**
   * Connecte l’utilisateur.
   */
  const login = useCallback(
    function (user) {
      mutate(user);
    },
    [mutate],
  );

  const can = useCallback(
    function ({ authorizations, environmentId }) {
      return root || usersCan({ authorizations, environmentId, user });
    },
    [root, user],
  );

  const value = useMemo(() => {
    const value = {
      loading,
      logged,
      user: user?.id ? user : undefined,
      mutate,
      login,
      logout,
      can,
      root,
    };
    return value;
  }, [can, loading, logged, login, logout, mutate, root, user]);

  return (
    <AuthAsContext.Provider value={value}>{children}</AuthAsContext.Provider>
  );
}

export default AuthAsProvider;
