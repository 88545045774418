export default {
  variants: {
    outline: {
      field: {
        _focus: {
          borderColor: "var(--chakra-ui-focus-outline-color)",
          boxShadow: "0 0 0 1px var(--chakra-ui-focus-outline-color)",
        },
      },
    },
  },
};
