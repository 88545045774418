// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

export const adminBaseUri = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  null,
  null,
  null,
);

export const adminBaseUriWithBasePath = new Uri(
  // @ts-ignore
  process.env.NEXT_PUBLIC_ADMIN_SCHEME,
  null,
  process.env.NEXT_PUBLIC_ADMIN_HOST,
  null,
  process.env.NEXT_PUBLIC_ADMIN_BASE_PATH,
  null,
  null,
);

/**
 * Build a router.
 * @param {import ("@splitfire-agency/raiden-library/dist/libraries/Uri").default} baseUri
 * @return {import ("@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router").default})}
 */
function buildRouter(baseUri) {
  const adminRouter = new Router();

  adminRouter.group(baseUri, (adminRouter) => {
    // assets (previewer)
    adminRouter
      .get("/public/<filePath>")
      .setId("assets")
      .setConstraint("filePath", /.*/);

    // internal assets (nextjs)
    adminRouter
      .get(`/<filePath>`)
      .setId("internal-assets")
      .setConstraint("filePath", /.*/);

    adminRouter.get("").setId("home");

    // Login
    adminRouter.get("/login").setId("login");

    // Password recovery
    adminRouter.get("/password-recovery").setId("password-recovery");

    // Dashboard
    adminRouter.get("/dashboard").setId("dashboard");

    // Health
    adminRouter.group("/health/status", (adminRouter) => {
      adminRouter.get("").setId("@healthStatus.view");
    });

    // Internal
    adminRouter.group("/internal", (adminRouter) => {
      adminRouter.group("/horizon", (adminRouter) => {
        adminRouter.get("").setId("@internalHorizon.view");
      });
      adminRouter.group("/telescope", (adminRouter) => {
        adminRouter.get("").setId("@internalTelescope.view");
      });
    });

    // Environments
    adminRouter.group("/environments", (adminRouter) => {
      adminRouter.get("").setId("@environments.viewAny");

      adminRouter.get("/create").setId("@environments.create");

      adminRouter.group("/<environmentId>", (adminRouter) => {
        adminRouter.setConstraint("environmentId", /\d+/);

        adminRouter.get("/show").setId("@environments.view");

        adminRouter.group("/edit", (adminRouter) => {
          adminRouter.get("").setId("@environments.update");
          adminRouter
            .get("/records_accounts")
            .setId("@environments.editRecordsAccounts");
        });
      });
    });

    adminRouter.group("/users", (adminRouter) => {
      // Users/Admins
      adminRouter.group("/admins", (adminRouter) => {
        adminRouter.get("").setId("@usersAdmins.viewAny");

        adminRouter.get("/create").setId("@usersAdmins.create");

        adminRouter.group("/me", function (adminRouter) {
          adminRouter.get("").setId("@usersAdmins.me");
          adminRouter.get("/2fa").setId("@usersAdmins.me.2fa");
          adminRouter
            .get("/notification-preferences")
            .setId("@usersAdmins.me.notificationPreferences");
        });

        adminRouter.group("/<usersAdminId>", (adminRouter) => {
          adminRouter.setConstraint("usersAdminId", /\d+/);

          adminRouter.get("/show").setId("@usersAdmins.view");

          adminRouter.get("/edit").setId("@usersAdmins.update");
        });
      });

      // Users/Groups
      adminRouter.group("/groups", (adminRouter) => {
        adminRouter.get("").setId("@usersGroups.viewAny");

        adminRouter.get("/create").setId("@usersGroups.create");

        adminRouter.group("/<usersGroupId>", (adminRouter) => {
          adminRouter.setConstraint("usersGroupId", /\d+/);

          adminRouter.get("/show").setId("@usersGroups.view");

          adminRouter.get("/edit").setId("@usersGroups.update");
        });
      });
    });

    adminRouter.group("/posts", (adminRouter) => {
      adminRouter.group("/<postType>", (adminRouter) => {
        adminRouter.setConstraint("postType", /[a-z]+/);

        adminRouter.get("/create").setId("@posts.create");

        adminRouter.get("").setId("@posts.viewAny");

        adminRouter.group("/categories", (adminRouter) => {
          adminRouter.get("").setId("@postsCategories.viewAny");

          adminRouter.get("/create").setId("@postsCategories.create");

          adminRouter.get("/tree-edit").setId("@postsCategories.treeUpdate");

          adminRouter.group("/<postsCategoryId>", (adminRouter) => {
            adminRouter.get("/show").setId("@postsCategories.view");

            adminRouter.get("/edit").setId("@postsCategories.update");
          });
        });

        adminRouter.group("/<postId>", (adminRouter) => {
          adminRouter.setConstraint("postId", /\d+/);

          adminRouter.get("/show").setId("@posts.view");

          adminRouter.get("/edit-revision").setId("@posts.updateRevision");
        });
      });
    });

    // Countries
    adminRouter.group("/countries", (adminRouter) => {
      adminRouter.get("").setId("@countries.viewAny");
      adminRouter.get("/create").setId("@countries.create");
      adminRouter.group("/<countryId>", (adminRouter) => {
        adminRouter.setConstraint("countryId", /\d+/);
        adminRouter.get("/edit").setId("@countries.update");
      });
    });

    // Menus
    adminRouter.group("/menus", (adminRouter) => {
      adminRouter.get("").setId("@menus.viewAny");
      adminRouter.get("/create").setId("@menus.create");

      adminRouter.group("/<menuId>", (adminRouter) => {
        adminRouter.get("/view").setId("@menus.view");
        adminRouter.get("/update").setId("@menus.update");
      });
    });

    // Invoices
    adminRouter.group("/invoices", (adminRouter) => {
      adminRouter.get("").setId("@invoices.viewAny");
      adminRouter.get("/create").setId("@invoices.create");

      adminRouter.group("/<invoiceId>", (adminRouter) => {
        adminRouter.get("").setId("@invoices.view");
      });
    });

    // Account records
    adminRouter.group("/account-records", (adminRouter) => {
      adminRouter.get("").setId("@accountRecords.viewAny");
    });

    // Customers
    adminRouter.group("/customers", (adminRouter) => {
      adminRouter.get("").setId("@customers.viewAny");
      adminRouter.get("/create").setId("@customers.create");

      adminRouter.group("/<customerId>", (adminRouter) => {
        adminRouter.setConstraint("customerId", /\d+/);
        adminRouter.get("/show").setId("@customers.view");
        adminRouter.get("/edit").setId("@customers.update");
        adminRouter.get("/documents").setId("@customers.documents");
        adminRouter.get("/holders").setId("@customers.holders");
        adminRouter.get("/validation").setId("@customers.validation");
        adminRouter
          .get("/notification-preferences")
          .setId("@customers.notificationPreferences");
      });
    });

    // Carriers
    adminRouter.group("/carriers", (adminRouter) => {
      adminRouter.get("").setId("@carriers.viewAny");
      adminRouter.get("/create").setId("@carriers.create");

      adminRouter.group("/<carrierId>", (adminRouter) => {
        adminRouter.setConstraint("carrierId", /\d+/);
        adminRouter.get("/show").setId("@carriers.view");
        adminRouter.get("/edit").setId("@carriers.update");
      });
    });

    // classrooms
    adminRouter.group("/classrooms", (adminRouter) => {
      adminRouter.get("").setId("@classrooms.viewAny");
      adminRouter.get("/create").setId("@classrooms.create");

      adminRouter.group("/<classroomId>", (adminRouter) => {
        adminRouter.get("").setId("@classrooms.view");
        adminRouter.get("/edit").setId("@classrooms.update");
      });
    });

    // places
    adminRouter.group("/places", (adminRouter) => {
      adminRouter.get("").setId("@places.viewAny");
      adminRouter.get("/create").setId("@places.create");

      adminRouter.group("/<placeId>", (adminRouter) => {
        adminRouter.get("").setId("@places.view");
        adminRouter.get("/update").setId("@places.update");
        adminRouter.get("/configure").setId("@places.configure");
      });
    });

    // years
    adminRouter.group("/years", (adminRouter) => {
      adminRouter.get("").setId("@years.viewAny");
      adminRouter.get("/create").setId("@years.create");

      adminRouter.group("/<yearId>", (adminRouter) => {
        adminRouter.get("").setId("@years.view");
        adminRouter.get("/edit").setId("@years.update");
      });
    });

    // holders
    adminRouter.group("/holders", (adminRouter) => {
      adminRouter.get("").setId("@holders.viewAny");
      adminRouter.get("/create").setId("@holders.create");

      adminRouter.group("/<holderId>", (adminRouter) => {
        adminRouter.get("").setId("@holders.view");
        adminRouter.get("/accounts").setId("@holders.accounts");
        adminRouter.get("/documents").setId("@holders.documents");
        adminRouter.get("/schoolings").setId("@holders.schoolings");
        adminRouter.get("/update").setId("@holders.update");
        adminRouter.get("/validation").setId("@holders.validation");
      });
    });

    // documents
    adminRouter.group("/documents", (adminRouter) => {
      adminRouter.get("").setId("@documents.viewAny");
      adminRouter.get("/create").setId("@documents.create");
      adminRouter.group("/<documentId>", (adminRouter) => {
        adminRouter.get("/update").setId("@documents.update");
      });
    });

    // subscriptions
    adminRouter.group("/subscriptions", (adminRouter) => {
      adminRouter.get("").setId("@subscriptions.viewAny");
      adminRouter.get("/create").setId("@subscriptions.create");
      adminRouter.group("/<subscriptionId>", (adminRouter) => {
        adminRouter.get("").setId("@subscriptions.view");
        adminRouter.get("/update").setId("@subscriptions.update");
      });

      // subscriptions/customers
      adminRouter.group("/customers", (adminRouter) => {
        adminRouter.get("").setId("@subscriptions.customers.viewAny");
        adminRouter.get("/create").setId("@subscriptions.customers.create");
        adminRouter.group("/<subscriptionCustomerId>", (adminRouter) => {
          adminRouter.get("/update").setId("@subscriptions.customers.update");
        });
      });
    });

    // prestations
    adminRouter.group("/prestations", (adminRouter) => {
      adminRouter.get("").setId("@prestations.viewAny");
      adminRouter.get("/create").setId("@prestations.create");
      adminRouter.group("/<prestationId>", (adminRouter) => {
        adminRouter.get("").setId("@prestations.view");
        adminRouter.get("/update").setId("@prestations.update");
      });

      // prestations/categories
      adminRouter.group("/categories", (adminRouter) => {
        adminRouter.get("").setId("@prestations.categories.viewAny");
        adminRouter.get("/create").setId("@prestations.categories.create");
        adminRouter.group("/<prestationCategoryId>", (adminRouter) => {
          adminRouter.get("").setId("@prestations.categories.view");
          adminRouter.get("/update").setId("@prestations.categories.update");
        });
      });

      // prestations/groups
      adminRouter.group("/groups", (adminRouter) => {
        adminRouter.get("").setId("@prestations.groups.viewAny");
        adminRouter.get("/create").setId("@prestations.groups.create");
        adminRouter.group("/<prestationGroupId>", (adminRouter) => {
          adminRouter.get("").setId("@prestations.groups.view");
          adminRouter.get("/update").setId("@prestations.groups.update");
        });
      });
    });

    // payments
    adminRouter.group("/payments", (adminRouter) => {
      adminRouter.get("").setId("@payments.viewAny");
      adminRouter.get("/create").setId("@payments.create");
      adminRouter.group("/<paymentId>", (adminRouter) => {
        adminRouter.get("").setId("@payments.view");
        adminRouter.get("/update").setId("@payments.update");
      });
    });

    // sessions
    adminRouter.group("/sessions", (adminRouter) => {
      adminRouter.get("").setId("@sessions.viewAny");
      adminRouter.get("/create").setId("@sessions.create");
      adminRouter.group("/<sessionId>", (adminRouter) => {
        adminRouter.get("").setId("@sessions.view");
        adminRouter.get("/update").setId("@sessions.update");
      });

      // sessions/registrations
      adminRouter.group("/registrations", (adminRouter) => {
        adminRouter.get("").setId("@sessions.registrations.viewAny");
        adminRouter.get("/create").setId("@sessions.registrations.create");
        adminRouter.group("/<sessionRegistrationId>", (adminRouter) => {
          adminRouter.get("").setId("@sessions.registrations.view");
        });

        // sessions/registrations/dates
        adminRouter.group("/dates", (adminRouter) => {
          adminRouter.get("").setId("@sessions.registrations.dates.viewAny");
          adminRouter
            .get("/calendar")
            .setId("@sessions.registrations.dates.calendar");
          adminRouter
            .get("/invoiced")
            .setId("@sessions.registrations.dates.invoiced");
          adminRouter
            .get("/create")
            .setId("@sessions.registrations.dates.create");
          adminRouter.group("/<sessionRegistrationDateId>", (adminRouter) => {
            adminRouter.get("").setId("@sessions.registrations.dates.view");
          });

          adminRouter.group("/planning", (adminRouter) => {
            adminRouter.group("/<customerId>", (adminRouter) => {
              adminRouter
                .get("")
                .setId("@sessions.registrations.dates.planning");
            });
          });
        });
      });
    });

    // badger
    adminRouter.group("/badger", (adminRouter) => {
      adminRouter.get("").setId("@badger.viewAny");
      adminRouter.group("/<prestationId>", (adminRouter) => {
        adminRouter.get("").setId("@badger.view");
      });
    });

    // Api
    adminRouter.group("/api", (adminRouter) => {
      // Pampa
      adminRouter
        .get("/resize<slug>")
        .setId("pampa")
        .setConstraint("slug", /.*/);
    });
  });

  return adminRouter;
}

export const adminRouter = buildRouter(adminBaseUri);
export const adminRouterWithBasePath = buildRouter(adminBaseUriWithBasePath);
