// deps
import { createContext, useContext, useMemo } from "react";
import { useDisclosure } from "@chakra-ui/react";

/**
 * @typedef {object} MaintenanceModeContextValue
 * @property {() => void} onOpen
 * @property {() => void} onClose
 * @property {boolean} isOpen
 */

/** @type {MaintenanceModeContextValue} */
const DEFAULT_VALUE = {
  onOpen: () => {},
  onClose: () => {},
  isOpen: false,
};

/** @type {import("react").Context<MaintenanceModeContextValue>} */
const MaintenanceModeContext = createContext(DEFAULT_VALUE);

export function MaintenanceModeProvider(props) {
  const { children } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const value = useMemo(
    function () {
      return { isOpen, onOpen, onClose };
    },
    [isOpen, onOpen, onClose],
  );

  return (
    <MaintenanceModeContext.Provider value={value}>
      {children}
    </MaintenanceModeContext.Provider>
  );
}

export function useMaintenanceMode() {
  return useContext(MaintenanceModeContext);
}
