// deps
import cookie from "cookie";
import { createContext, memo, useContext, useMemo, useState } from "react";

// contexts
import { usePreferences } from "@raiden/library/contexts/Preferences";
import { useInitialVariables } from "@splitfire-agency/raiden-library/dist/contexts/InitialVariables";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

// constants
import { COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS } from "@raiden/library/constants/cookies";

export const DefaultEnvironmentsContext = createContext(
  /**
   * @type {object}
   * @property {string[]} defaultEnvironments
   * @property {(defaultEnvironment: any) => void} setDefaultEnvironments
   **/ (null),
);

const { Provider } = DefaultEnvironmentsContext;

export const DefaultEnvironmentsProvider = memo(
  function DefaultEnvironmentsProvider(props) {
    const { bestEnvironment } = usePreferences();

    const { cookies } = useInitialVariables();

    let {
      [COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS]: initialDefaultEnvironment = "",
    } = cookies;

    initialDefaultEnvironment = initialDefaultEnvironment
      ? initialDefaultEnvironment.split(",")
      : [];

    if (initialDefaultEnvironment.length === 0 && bestEnvironment) {
      initialDefaultEnvironment = [bestEnvironment.id];
    }

    const [defaultEnvironments, setDefaultEnvironments] = useState(
      initialDefaultEnvironment,
    );

    const value = useMemo(() => {
      return {
        defaultEnvironments,
        setDefaultEnvironments: function (defaultEnvironment) {
          setDefaultEnvironments(defaultEnvironment);

          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_DEFAULT_ENVIRONMENTS,
            defaultEnvironment,
            {
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
              secure: true,
            },
          );
        },
      };
    }, [defaultEnvironments]);

    return <Provider {...props} value={value} />;
  },
);

export const useDefaultEnvironments = function () {
  return useContext(DefaultEnvironmentsContext);
};
