import { Box } from "@chakra-ui/react";
import { createContext, useEffect, useMemo, useRef, useState } from "react";
import useSWR from "swr";
import Error from "../../components/Error";
import useAuthAs from "../../hooks/auth/as";
import useApiFetcher from "../../hooks/useApiFetcher";

/**
 * @typedef {object} ConfigurationContextValue
 * @property {import("@raiden/library/types/Configuration").Configuration} configuration
 * @property {() => void} mutate
 */

/** @type {ConfigurationContextValue} */
const DefaultValue = {
  configuration: {
    environments: [],
    is_restricted: false,
    years: [],
  },
  mutate: () => {},
};

/** @type {React.Context<ConfigurationContextValue>} */
export const ConfigurationContext = createContext(DefaultValue);

/**
 * @typedef {Object} Props
 * @property {import("@raiden/library/types/Configuration").Configuration} initialConfiguration
 * @property {import("@raiden/library/types/User").UserBase} initialUser
 * @property {string | null} [url]
 * @property {React.ReactNode} children
 *
 * @param {Props} props
 */
export const ConfigurationProvider = ({
  children,
  initialConfiguration: _initialConfiguration,
  initialUser,
  url = null,
}) => {
  const apiFetcher = useApiFetcher();

  const initialConfiguration = useRef(_initialConfiguration);

  const { data, mutate, error } = useSWR(url, apiFetcher, {
    revalidateOnMount: false,
    revalidateOnFocus: true,
    refreshInterval: 0,
    dedupingInterval: 0,
    fallbackData: {
      data: initialConfiguration.current,
    },
  });

  /** @type {import("@raiden/library/types/Configuration").Configuration} */
  const configuration = data?.data; // forcing type as the configuration cannot be undefined

  const value = useMemo(() => {
    return {
      configuration,
      mutate,
    };
  }, [configuration, mutate]);

  const { user } = useAuthAs();

  const [remanentUser, setRemanentUser] = useState(user);

  useEffect(() => {
    if (user?.id !== remanentUser?.id) {
      setRemanentUser(user);
      mutate();
    }
  }, [initialUser, mutate, remanentUser?.id, user]);

  return (
    <ConfigurationContext.Provider value={value}>
      {error ? (
        <Box p="1rem">
          <Error.Global error={error} />
        </Box>
      ) : (
        children
      )}
    </ConfigurationContext.Provider>
  );
};
