/***
 * Recherche l'environement passé en paramètre
 * @param {object} param0
 * @param {number} param0.id
 * @param {import("../../../types/Environment").Environment[]} param0.environmentList
 * @returns {object}
 */
export default function environmentsGetEnvironmentById({
  id,
  environmentList,
}) {
  return environmentList.find((environment) => environment.id === id);
}
