// helpers
import { usersCan, usersIsRoot } from "../../users";
import { environmentsGetEnvironmentListRelyOnAuthorizations } from "../../environments";

/**
 * Retourne si l’utilisateur courant peut accéder à la page.
 * @param {object} param0
 * @param {import("../../../types/User").UserBase} param0.user
 * @param {object} param0.guard
 * @param {boolean} [param0.withEnvironmentGuard = false ]
 * @param {object} [param0.unSecureCookies]
 */
export default async function nextIsAuthorized({
  user,
  guard,
  withEnvironmentGuard = false,
  unSecureCookies = {},
}) {
  let authorize = {
    globally: true,
    locally: true,
  };
  if (guard) {
    if (guard.authorizations) {
      if (!usersCan({ authorizations: guard.authorizations, user })) {
        authorize.globally = false;
        return authorize;
      }

      if (withEnvironmentGuard) {
        const defaultEnvironments = unSecureCookies["default-environments"]
          ? unSecureCookies["default-environments"].split(",")
          : [];

        if (defaultEnvironments.length > 0) {
          const environments =
            environmentsGetEnvironmentListRelyOnAuthorizations({
              authorizations: guard.authorizations,
              user,
              environments: defaultEnvironments,
            });

          authorize.locally = environments.length > 0;
          return authorize;
        }
      }
    }

    if (guard.root && !usersIsRoot({ user })) {
      authorize.globally = false;
      return authorize;
    }
  }

  return authorize;
}
