// deps
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useIntl } from "react-intl";

// containers
import SignedInFooter from "./Footer";
import SignedInHeader from "./Header";
import Menu from "./Menu";

// components
import MaintenanceSplash from "../../components/MaintenanceSplash";

// contexts
import { useMaintenanceMode } from "@raiden/library/contexts/MaintenanceMode";
import { DefaultEnvironmentsProvider } from "../../contexts/DefaultEnvironments";

// constants
import { MENU_ITEMS } from "../../constants/menu";
import useAuthAs from "@raiden/library/hooks/auth/as";
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library/constants/users";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

export default function SignedInLayout(props) {
  const { children } = props;

  const intl = useIntl();

  const { user } = useAuthAs();

  const isMobileLayout =
    useBreakpointValue({
      base: true,
      lg: false,
    }) ?? false;

  const {
    isOpen: isOpenMenu,
    onClose: onCloseMenu,
    onToggle: onToggleMenu,
  } = useDisclosure();

  const router = useRouter();
  const { push } = router;

  useEffect(() => {
    const handler = () => {
      onCloseMenu();
    };
    router.events.on("routeChangeStart", handler);
    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [onCloseMenu, router.events]);

  const bgColor = useColorModeValue("white", "gray.800");

  const { isOpen: maintenanceModeIsOpen, onClose: maintenanceModeOnClose } =
    useMaintenanceMode();

  useEffect(() => {
    if (
      user?.user_type !== undefined &&
      user.user_type !== USERS_USER_TYPE_VALUE_ADMIN
    ) {
      push(generateAdminPath({ id: "login" }));
    }
  }, [push, user?.user_type]);

  return (
    <>
      <Modal
        isOpen={maintenanceModeIsOpen}
        size="xl"
        onClose={maintenanceModeOnClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />

          <ModalHeader>
            {intl.formatMessage({
              defaultMessage: "Maintenance en cours",
            })}
          </ModalHeader>

          <ModalBody>
            <MaintenanceSplash
              addon={
                <Button onClick={maintenanceModeOnClose}>
                  {intl.formatMessage({ defaultMessage: "Fermer" })}
                </Button>
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <DefaultEnvironmentsProvider>
        <Flex h="full" flexDir="column" pos="relative">
          <SignedInHeader onClickMenu={onToggleMenu} />

          <Flex flexGrow={1} position="relative" mt="4rem">
            <Flex flexGrow={1} flexDir="column" bgColor={bgColor} minW="0">
              <Box
                flexGrow={1}
                d="flex"
                flexDir="column"
                ml={isMobileLayout ? "0rem" : "15rem"}
                transition="margin .25s ease-in-out">
                {children}
              </Box>

              <SignedInFooter />
            </Flex>

            <Box flexShrink={0}>
              <Menu
                menuItems={MENU_ITEMS}
                isOpened={isOpenMenu}
                onClose={onCloseMenu}
                isMobileLayout={isMobileLayout}
              />
            </Box>
          </Flex>
        </Flex>
      </DefaultEnvironmentsProvider>
    </>
  );
}

SignedInLayout.propTypes = {
  children: PropTypes.node,
};
