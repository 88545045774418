// deps
import { useCallback, useMemo } from "react";
import { Button, HStack, useBreakpointValue, VStack } from "@chakra-ui/react";
import { useIntl } from "react-intl";

// components
import { IoChevronUpOutline } from "@raiden/library/components/Icon";
import Copyright from "../../../components/Copyright";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";

// containers
import LocaleSelect from "../../LocaleSelect";

export default function SignedInLayoutFooter(props) {
  const intl = useIntl();

  const responsive = useBreakpointValue({
    base: true,
    lg: false,
  });

  /**
   * Gère le clic sur le bouton pour remonter sur le haut de la page.
   */
  const handleClickTopOfThePage = useCallback(function () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const wrapper = useMemo(
    function () {
      return function (children) {
        return responsive ? (
          <VStack alignItems="flex-end" spacing="0.625rem" p="0.625rem">
            {children}
          </VStack>
        ) : (
          <HStack
            px="1rem"
            justifyContent="flex-end"
            spacing="1rem"
            py="0.625rem">
            {children}
          </HStack>
        );
      };
    },
    [responsive],
  );

  return (
    <ConditionalWrapper wrapper={wrapper} hasWrapper={true}>
      <Copyright />

      <HStack spacing="1rem">
        <LocaleSelect size="sm" />

        <Button
          colorScheme="gray"
          variant="link"
          onClick={handleClickTopOfThePage}
          rightIcon={<IoChevronUpOutline />}>
          {intl.formatMessage({
            id: "raiden.admin.containers.SignedInLayout.Footer.texts.topofThePage",
            defaultMessage: "Haut de page",
          })}
        </Button>
      </HStack>
    </ConditionalWrapper>
  );
}
