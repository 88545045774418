// deps
import { mode } from "@chakra-ui/theme-tools";

export default {
  global: (props) => ({
    "html, body": {
      height: "100%",
      bgColor: "gray.700",
    },
    body: {
      fontFamily: "Helvetica, sans-serif",
    },
    "#__next": {
      height: "100%",
    },
    "*::placeholder": {
      color: mode("gray.400", "gray.500")(props),
    },
    ":host,:root": {
      "--chakra-ui-focus-outline-color": "#40AEC4",
    },
    ".renderer h1": {
      fontFamily: "'Rubik', sans-serif",
      color: "#2D3748",
      fontSize: "3rem",
      fontWeight: "400",
      lineHeight: "normal",
    },
    ".renderer h2": {
      fontFamily: "'Rubik', sans-serif",
      color: "#2D3748",
      fontSize: "1.875rem",
      fontWeight: "400",
      lineHeight: "2.25rem",
    },
    ".renderer h3": {
      fontFamily: "'Rubik', sans-serif",
      color: "#718096",
      fontSize: "1.5rem",
      fontWeight: "400",
      lineHeight: "1.8rem",
    },
    ".renderer h4": {
      fontFamily: "'Rubik', sans-serif",
      color: "#718096",
      fontSize: "1.25rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
    },
    ".renderer h5": {
      fontFamily: "'Rubik', sans-serif",
      color: "#718096",
      fontSize: "1.125rem",
      fontWeight: "400",
      lineHeight: "1.35rem",
    },
    ".renderer h6": {
      fontFamily: "'Rubik', sans-serif",
      color: "#718096",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.2rem",
    },
    ".renderer p, .renderer .paragraph, .renderer ul": {
      fontFamily: "'Rubik', sans-serif",
      color: "#363D63",
      fontSize: "1rem",
      fontWeight: "400",
      lineHeight: "1.5rem",
      mt: "0.875rem",
      ":first-child": {
        mt: 0,
      },
    },
    ".renderer ul": {
      marginLeft: "1.5rem",
    },
  }),
};
