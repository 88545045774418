// deps
import { createContext, memo, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import useCanPerform from "../../hooks/useCanPerform";
import { useConfiguration } from "../../hooks/useConfiguration";

// hooks

/**
 * @typedef {Object} TGuardsContext
 * @property {any} guard
 * @property {object[]} environmentListRelyOnAuthorizations
 * @property {boolean} hasWidlcard
 */

/** @type {import("react").Context<TGuardsContext>} */
// @ts-ignore
export const GuardsContext = createContext(null);

/**
 * @param {object} props
 * @param {import("@raiden/library/types/Configuration").ConfigurationEnvironment[]} props.environments
 * @param {string[]} props.authorizations
 * @param {function} props.canPerform
 */
function getListEnvironmentRelayOnAuthorizations({
  environments,
  authorizations,
  canPerform,
}) {
  if (authorizations) {
    const environmentFiltered = environments.filter((environment) =>
      canPerform({
        environmentId: environment.id,
        authorizations,
      }),
    );
    return {
      environments: environmentFiltered,
      hasWidlcard: canPerform({ environmentId: "*", authorizations }),
    };
  }
  return { environments, hasWidlcard: false };
}

/**
 * @param {{
 * guard: any,
 * children: import("react").ReactNode,
 * }} props
 */
function _GuardsProvider(props) {
  const { guard, children } = props;
  const canPerform = useCanPerform();

  const { configuration } = useConfiguration();

  const { environments: localEnvironments, hasWidlcard } =
    getListEnvironmentRelayOnAuthorizations({
      environments: configuration?.environments ?? [],
      authorizations: guard?.authorizations,
      canPerform,
    });

  const value = useMemo(
    function () {
      return {
        guard,
        environmentListRelyOnAuthorizations: localEnvironments,
        hasWidlcard,
      };
    },
    [guard, localEnvironments, hasWidlcard],
  );

  return (
    <GuardsContext.Provider value={value}>{children}</GuardsContext.Provider>
  );
}

_GuardsProvider.displayName = "GuardsProvider";

_GuardsProvider.propTypes = {
  children: PropTypes.node,
  environments: PropTypes.array,
};

export const GuardsProvider = memo(_GuardsProvider);

export const useGuards = function () {
  return useContext(GuardsContext);
};
