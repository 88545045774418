// deps
import { forwardRef } from "react";

// components
import EnvironmentSelect from "@raiden/library/components/Form/EnvironmentSelect";

// context
import { usePreferences } from "@raiden/library/contexts/Preferences";

const EnvironmentSelectWithoutAuthorizations = forwardRef(
  /**
   * @typedef {Object} Props
   * @property {boolean} [isMultiple]
   * @property {boolean} [isDisabled]
   * @property {string} [placeholder]
   * @property {boolean} [includeWildcard]
   * @property {string} [labelWildcard]
   * @property {object[]} [environments]
   *
   * @param {Props & import("@chakra-ui/react").SelectProps} props
   */
  function EnvironmentSelectWithoutAuthorizations(props, ref) {
    const { ...otherProps } = props;

    const { environments } = usePreferences();

    return (
      <EnvironmentSelect
        ref={ref}
        environments={environments}
        {...otherProps}
      />
    );
  },
);

export default EnvironmentSelectWithoutAuthorizations;
