// polyfills
import "../polyfills";
import intlPolyfills from "../polyfills/intl";

// deps
import { SSRProvider } from "@react-aria/ssr";
import { I18nProvider } from "@react-aria/i18n";
import { OverlayProvider } from "@react-aria/overlays";
import PropTypes from "prop-types";
import { IntlProvider } from "react-intl";
import Head from "next/head";
import NextApp from "next/app";
import cookie from "cookie";
import { SWRConfig } from "swr";
import { ChakraProvider, cookieStorageManager } from "@chakra-ui/react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

// containers
import MaintenanceLayout from "../containers/MaintenanceLayout";
import SignedInLayout from "../containers/SignedInLayout";
import SignedOutLayout from "../containers/SignedOutLayout";
import Progress from "../containers/Progress";
import Unauthorized from "../containers/Unauthorized";
import TooManyRequest from "../containers/TooManyRequest";
import CookieBanner from "../containers/CookieBanner";
import MaintenanceRequest from "../containers/MaintenanceRequest";

// components
import ErrorGlobal from "@raiden/library/components/Error/Global";
import ErrorBoundary from "../components/ErrorBoundary";

// hooks
import { useMaintenance } from "../hooks/useMaintenance";

// contexts
import { InitialVariablesProvider } from "@splitfire-agency/raiden-library/dist/contexts/InitialVariables";
import { PreferencesProvider } from "@raiden/library/contexts/Preferences";
import { GoogleTrackingProvider } from "@raiden/library/contexts/GoogleTracking";
import { GuardsProvider } from "@raiden/library/contexts/Guards";
import { MaintenanceModeProvider } from "@raiden/library/contexts/MaintenanceMode";
import { ConfigurationProvider } from "@raiden/library/contexts/Configuration";
import AuthAsProvider from "@raiden/library/contexts/AuthAs";

// libraries
import generateAdminUri from "@raiden/library/libraries/utils/generateAdminUri";
import getTranslationMessages from "../libraries/utils/getTranslationMessages";

// helpers
import {
  nextCheckMaintenance,
  nextGetCookies,
  nextGetUser,
  nextGetConfiguration,
  nextGetLayout,
  nextIsAuthorized,
  nextGetUri,
} from "@raiden/library/helpers/next";
import { googleGetTrackingState } from "@raiden/library/helpers/google";
import { apiGetErrorStatus } from "@raiden/library/helpers/api";

// utils
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";

// constants
import theme from "../constants/theme";
import { adminBaseUri } from "@raiden/library/constants/routers/admin";
import { DEFAULT_PER_PAGE } from "@raiden/library/constants/api";
import {
  COOKIES_NAME_LIST,
  COOKIES_NAME_VALUE_ACCEPT_COOKIES,
  COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
  COOKIES_NAME_VALUE_PREFER_MENU_SHRINK,
} from "@raiden/library/constants/cookies";
import browser from "@raiden/library/constants/browser";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-image-crop/dist/ReactCrop.css";

import "focus-visible/dist/focus-visible";

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const SWR_CONFIG_VALUE = {
  revalidateOnFocus: false,
  errorRetryCount: 0,
  refreshWhenOffline: false,
};

const CONFIGURATION_URL = generateApiUri({
  id: "@configuration",
  query: {
    fields: [
      "globals.covers",
      "globals.files",
      "globals.schooling_levels",
      "globals.payments.methods",
      "globals.global_subscription",
      "environments",
      "records_main_accounts",
      "auth",
      "years",
    ],
  },
});

const DEFAULT_LOCALE = process.env.NEXT_PUBLIC_DEFAULT_LOCALE ?? "";

/**
 * Retourne le layout à afficher.
 * @param {object} param0
 * @param {"signedIn" | "signedOut" | string} param0.layout
 * @return {any}
 */
function getLayout({ layout }) {
  switch (layout) {
    case "signedOut":
      return SignedOutLayout;

    case "signedIn": {
      return SignedInLayout;
    }

    default:
      return SignedInLayout;
  }
}

/**
 * @param {{
 * Component: import("react").FunctionComponent,
 * pageProps: any,
 * cookies: any,
 * layout: any,
 * tooManyRequest: boolean,
 * maintenanceRequest: boolean,
 * authorized: object,
 * userData: import("@raiden/library/types/Api/ApiResponse").ApiResponse<import("@raiden/library/types/User").UserBase>,
 * environments: import("@raiden/library/types/Environment").Environment[],
 * configuration: import("@raiden/library/types/Configuration").Configuration,
 * locale: string,
 * translationMessages: any,
 * acceptLanguage: any,
 * error: object,
 * guard: any,
 * }} props
 */
export default function App(props) {
  const {
    Component,
    pageProps,
    cookies,
    layout,
    tooManyRequest,
    maintenanceRequest,
    authorized,
    userData,
    environments,
    configuration,
    locale,
    translationMessages: messages,
    acceptLanguage,
    error,
  } = props;

  const isMaintenance = useMaintenance({ cookies });

  const colorModeManager = cookieStorageManager(
    cookie.serialize("chakra-ui-color-mode", cookies["chakra-ui-color-mode"]),
  );

  const Layout = getLayout({ layout });

  return (
    <>
      <Head>
        <link
          rel="icon"
          href={generateAdminPath({
            id: "internal-assets",
            parameters: { filePath: "favicon.png" },
          })}
        />
      </Head>

      <GoogleTrackingProvider
        initialState={googleGetTrackingState({
          cookieValue: cookies[COOKIES_NAME_VALUE_ACCEPT_COOKIES],
        })}
        trackingKey={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_KEY}
        trackingService={process.env.NEXT_PUBLIC_GOOGLE_TRACKING_SERVICE}>
        <InitialVariablesProvider
          cookies={cookies}
          userData={userData}
          configuration={configuration}
          acceptLanguage={acceptLanguage}>
          <SWRConfig value={SWR_CONFIG_VALUE}>
            <IntlProvider
              locale={locale}
              defaultLocale={DEFAULT_LOCALE}
              messages={messages}>
              <I18nProvider locale={locale}>
                <SSRProvider>
                  <MaintenanceModeProvider>
                    <ChakraProvider
                      theme={theme}
                      colorModeManager={colorModeManager}>
                      <AuthAsProvider initialUser={userData?.data}>
                        <ConfigurationProvider
                          url={CONFIGURATION_URL}
                          initialConfiguration={configuration}
                          initialUser={userData?.data}>
                          <Progress />

                          <PreferencesProvider
                            initialEnvironments={environments}
                            initialPreferMenuShrink={
                              cookies[COOKIES_NAME_VALUE_PREFER_MENU_SHRINK] ===
                              "1"
                            }
                            initialPaginationPerPage={
                              cookies[COOKIES_NAME_VALUE_PAGINATION_PER_PAGE] ??
                              DEFAULT_PER_PAGE
                            }>
                            <ErrorBoundary>
                              {isMaintenance ? (
                                <MaintenanceLayout />
                              ) : (
                                <Layout>
                                  <OverlayProvider
                                    style={{ minHeight: "100%" }}>
                                    <GuardsProvider
                                      guard={pageProps?.guard ?? {}}>
                                      {(() => {
                                        if (tooManyRequest) {
                                          return <TooManyRequest />;
                                        }
                                        if (maintenanceRequest) {
                                          return <MaintenanceRequest />;
                                        }
                                        if (error) {
                                          return <ErrorGlobal error={error} />;
                                        }
                                        if (
                                          !authorized.locally ||
                                          !authorized.globally
                                        ) {
                                          return (
                                            <Unauthorized
                                              authorized={authorized}
                                            />
                                          );
                                        }
                                        return (
                                          <>
                                            <Component {...pageProps} />

                                            <CookieBanner />
                                          </>
                                        );
                                      })()}
                                    </GuardsProvider>
                                  </OverlayProvider>
                                </Layout>
                              )}
                            </ErrorBoundary>
                          </PreferencesProvider>
                        </ConfigurationProvider>
                      </AuthAsProvider>
                    </ChakraProvider>
                  </MaintenanceModeProvider>
                </SSRProvider>
              </I18nProvider>
            </IntlProvider>
          </SWRConfig>
        </InitialVariablesProvider>
      </GoogleTrackingProvider>
    </>
  );
}

App.getInitialProps = async function (appContext) {
  const {
    router: { locale },
  } = appContext;

  await nextCheckMaintenance({ res: appContext.ctx.res });

  const { cookies, unSecureCookies } = await nextGetCookies({
    req: appContext.ctx.req,
    whitelist: COOKIES_NAME_LIST.reduce(function (cookies, { id: cookieName }) {
      cookies[cookieName] = true;

      return cookies;
    }, {}),
  });

  const appProps = await NextApp.getInitialProps(appContext);

  const [translationMessages, userResponse] = await Promise.all([
    getTranslationMessages(locale, DEFAULT_LOCALE),
    nextGetUser({
      cookies,
      baseUri: adminBaseUri,
      locale,
      req: appContext.ctx.req,
    }),
    intlPolyfills(locale),
  ]);

  // only fetch configuration on the server
  const configurationResponse =
    typeof window === "undefined"
      ? await nextGetConfiguration({
          cookies,
          baseUri: adminBaseUri,
          locale,
          req: appContext.ctx.req,
          fields: [
            "globals.covers",
            "globals.files",
            "globals.schooling_levels",
            "globals.payments.methods",
            "environments",
            "records_main_accounts",
            "auth",
            "years",
          ],
        })
      : null;

  const configuration = configurationResponse?.configuration;
  const configurationResponseError = configurationResponse?.error;

  const userData = userResponse?.data;
  const user = userResponse?.user;
  const logged = userResponse?.logged;
  const userResponseError = userResponse?.error;

  const environments = configuration?.environments ?? [];

  const error = configurationResponseError || userResponseError;

  const tooManyRequest =
    429 === apiGetErrorStatus({ error: configurationResponseError }) ||
    429 === apiGetErrorStatus({ error: userResponseError });

  const maintenanceRequest =
    503 === apiGetErrorStatus({ error: configurationResponseError }) ||
    503 === apiGetErrorStatus({ error: userResponseError });

  const uri = nextGetUri({
    req: appContext.ctx.req,
    asPath: appContext.ctx.asPath,
  });

  const layout = await nextGetLayout({
    pageLayout: appProps?.pageProps?.layout,
    tooManyRequest,
    res: appContext.ctx.res,
    logged,
    user,
    redirections: {
      signedOut: generateAdminUri({
        id: "login",
        query: {
          next: encodeURIComponent(
            `${uri.getPath()}${uri.getQuery() ? `?${uri.getQuery()}` : ""}`,
          ),
        },
        includeBasePath: true,
      }),
      signedIn: generateAdminUri({ id: "dashboard", includeBasePath: true }),
    },
  });

  const authorized = await nextIsAuthorized({
    guard: appProps?.pageProps?.guard,
    user,
    unSecureCookies,
    withEnvironmentGuard: true,
  });

  const acceptLanguage = browser
    ? window.navigator.languages.join(",")
    : appContext.ctx.req.headers["accept-language"];

  return {
    ...appProps,
    locale,
    layout,
    cookies: unSecureCookies,
    userData,
    environments,
    tooManyRequest,
    maintenanceRequest,
    error,
    authorized,
    configuration,
    translationMessages,
    acceptLanguage,
  };
};

App.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.any,
  locale: PropTypes.string,
  layout: PropTypes.string,
  cookies: PropTypes.object,
  authorized: PropTypes.shape({
    globally: PropTypes.bool,
    locally: PropTypes.bool,
  }),
  tooManyRequest: PropTypes.bool,
  maintenanceRequest: PropTypes.bool,
  error: PropTypes.object,
  userData: PropTypes.any,
  environments: PropTypes.array,
  configuration: PropTypes.object,
  translationMessages: PropTypes.object,
};
