// deps
import { extendTheme } from "@chakra-ui/react";

// constants
import breakpoints from "./breakpoints";
import styles from "./styles";
import Button from "./components/button";
import Checkbox from "./components/checkbox";
import Input from "./components/input";
import Link from "./components/link";
import Switch from "./components/switch";
import Table from "./components/table";
import Text from "./components/text";
import Textarea from "./components/textarea";
import Select from "./components/select";

const theme = extendTheme({
  breakpoints,
  colors: {
    brandPrimary: {
      50: "#D5EDF2",
      100: "#B4E1EB",
      200: "#8CCEDB",
      300: "#65BDCE",
      400: "#40AEC4",
      500: "#2895AB",
      600: "#1C7182",
      700: "#115361",
      800: "#05343D",
      900: "#001317",
    },
    brandSecondary: {
      50: "#FFF0DD",
      100: "#FADBB4",
      200: "#F4C888",
      300: "#EEB75B",
      400: "#EAB046",
      500: "#EAA82F",
      600: "#D08315",
      700: "#A25B0E",
      800: "#753908",
      900: "#461D00",
    },
    darkGray: {
      50: "#F7FAFC",
      100: "#EDF2F7",
      200: "#E2E8F0",
      300: "#CBD5E0",
      400: "#A0AEC0",
      500: "#718096",
      600: "#4A5568",
      700: "#2D3748",
      800: "#1A202C",
      900: "#171923",
    },
  },
  components: {
    Button,
    Checkbox,
    Input,
    Link,
    Select,
    Switch,
    Table,
    Text,
    Textarea,
  },
  styles,
  shadows: {
    outline: "0 0 0 3px var(--chakra-ui-focus-outline-color)",
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
});

export default theme;
