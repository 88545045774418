// deps
import { useIntl } from "react-intl";
import Head from "next/head";
import { Box, Button, VStack } from "@chakra-ui/react";

// components
import MaintenanceSplash from "../../components/MaintenanceSplash";
import PagePanel from "../../components/PagePanel";

function MaintenanceRequest() {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            defaultMessage: "Patientez un instant",
          })}
        </title>
      </Head>

      <PagePanel.Viewport>
        <VStack spacing="1rem">
          <MaintenanceSplash />

          <Box>
            <Button
              variant="solid"
              onClick={function () {
                window.location.reload();
              }}>
              {intl.formatMessage({
                defaultMessage: "Recharger la page",
              })}
            </Button>
          </Box>
        </VStack>
      </PagePanel.Viewport>
    </>
  );
}

export default MaintenanceRequest;
