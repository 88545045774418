// hooks
import { useCallback } from "react";
import useAuthAs from "./auth/as";

/**
 * @callback CanPerform
 * @param {{
 * authorizations?: string | string[],
 * resourcePolicies?: string[],
 * policies?: string | string[],
 * environmentId?: number,
 * }} params
 * @returns {boolean}
 */

export default function useCanPerform() {
  const { can } = useAuthAs();

  const canPerform = useCallback(
    /** @type {CanPerform} */
    function ({
      authorizations,
      resourcePolicies = [],
      policies: _policies,
      environmentId,
    }) {
      const policies = Array.isArray(_policies)
        ? _policies
        : [...(typeof _policies === "string" ? [_policies] : [])];

      if (authorizations && !can({ authorizations, environmentId })) {
        return false;
      }

      if (
        policies &&
        !policies.every(function (policy) {
          return resourcePolicies.includes(policy);
        })
      ) {
        return false;
      }

      return true;
    },
    [can],
  );

  return canPerform;
}
