/* Autorisations */

// countries
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY =
  "countries.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW =
  "countries.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_CREATE =
  "countries.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_UPDATE =
  "countries.update";

// environments
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_CREATE =
  "environments.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY =
  "environments.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW =
  "environments.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_QUOTAS =
  "environments.quptas";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_UPDATE =
  "environments.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_DELETE =
  "environments.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_SOFT_DELETE =
  "environments.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_RESTORE =
  "environments.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_UPDATE_RECORDS_ACCOUNTS =
  "environments.update_records_accounts";

// users/groups
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY =
  "users.groups.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW =
  "users.groups.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_CREATE =
  "users.groups.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_UPDATE =
  "users.groups.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_DELETE =
  "users.groups.delete";

// users/admins
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY =
  "users.admins.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW =
  "users.admins.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_CREATE =
  "users.admins.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_UPDATE =
  "users.admins.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_DELETE =
  "users.admins.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_PASSWORD_RESETS =
  "users.admins.password_resets";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_ACCESS_TOKENS =
  "users.admins.access_tokens";

// posts
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY =
  "posts.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW = "posts.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CREATE = "posts.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_UPDATE = "posts.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_SOFT_DELETE =
  "posts.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_DELETE = "posts.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_RESTORE = "posts.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_PUBLISH = "posts.publish";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_DISABLE = "posts.disable";

// posts/revisions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_VIEW_ANY =
  "posts.revisions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_VIEW =
  "posts.revisions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_UPDATE =
  "posts.revisions.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_DELETE =
  "posts.revisions.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_SOFT_DELETE =
  "posts.revisions.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_RESTORE =
  "posts.revisions.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_REVIEW =
  "posts.revisions.review";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_REVISIONS_REQUEST_REVIEW =
  "posts.revisions.request_review";

// posts/categories
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW_ANY =
  "posts.categories.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW =
  "posts.categories.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_CREATE =
  "posts.categories.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_UPDATE =
  "posts.categories.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_SOFT_DELETE =
  "posts.categories.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_DELETE =
  "posts.categories.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_RESTORE =
  "posts.categories.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_UPDATE_TREE =
  "posts.categories.update_tree";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_CATEGORIES_VIEW_TREE =
  "posts.categories.view_tree";

// files
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_VIEW_ANY =
  "files.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_VIEW = "files.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_CREATE = "files.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_UPDATE = "files.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_DELETE = "files.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_SOFT_DELETE =
  "files.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_RESTORE = "files.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_FILES_DOWNLOAD =
  "files.download";

// menus
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY =
  "menus.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW = "menus.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_CREATE = "menus.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_UPDATE = "menus.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_UPDATE_ITEMS =
  "menus.update_items";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_DELETE = "menus.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_SOFT_DELETE =
  "menus.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_RESTORE = "menus.restore";

// invoices
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY =
  "invoices.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW = "invoices.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_DOWNLOAD =
  "invoices.download";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_EXPORT =
  "invoices.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_CREATE =
  "invoices.create";

// accountsRecords
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY =
  "accounts_records_exports.view_any";

// customers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY =
  "users.customers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW =
  "users.customers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_CREATE =
  "users.customers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_UPDATE =
  "users.customers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DELETE =
  "users.customers.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_SOFT_DELETE =
  "users.customers.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_RESTORE =
  "users.customers.soft_restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_JUSTIFICATIVE =
  "users.customers.justificative";

// aresses
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_VIEW_ANY =
  "users.addresses.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_VIEW =
  "users.addresses.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_CREATE =
  "users.addresses.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_UPDATE =
  "users.addresses.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_DELETE =
  "users.addresses.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_SOFT_DELETE =
  "users.addresses.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_ADDRESSES_RESTORE =
  "users.addresses.restore";

// carriers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY =
  "carriers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW = "carriers.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_CREATE =
  "carriers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_UPDATE =
  "carriers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_SOFT_DELETE =
  "carriers.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_DELETE =
  "carriers.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_RESTORE =
  "carriers.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_ASSOCIATE =
  "carriers.associate";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_DISSOCIATE =
  "carriers.dissociate";

// classrooms
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_VIEW_ANY =
  "classrooms.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_VIEW =
  "classrooms.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_CREATE =
  "classrooms.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_UPDATE =
  "classrooms.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_DELETE =
  "classrooms.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_SOFT_DELETE =
  "classrooms.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_RESTORE =
  "classrooms.restore";

// places
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_VIEW_ANY =
  "places.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_VIEW = "places.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_CREATE = "places.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_UPDATE = "places.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_DELETE = "places.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_SOFT_DELETE =
  "places.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_RESTORE =
  "places.restore";

// years
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_VIEW_ANY =
  "years.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_VIEW = "years.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_CREATE = "years.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_UPDATE = "years.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_DELETE = "years.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_SOFT_DELETE =
  "years.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_RESTORE = "years.restore";

// holders
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_VIEW_ANY =
  "holders.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_VIEW = "holders.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_CREATE =
  "holders.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_UPDATE =
  "holders.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_DELETE =
  "holders.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_SOFT_DELETE =
  "holders.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_RESTORE =
  "holders.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_ASSOCIATE =
  "holders.associate";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_DISSOCIATE =
  "holders.dissociate";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_EXPORT =
  "holders.export";

// documents
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_VIEW_ANY =
  "documents.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_VIEW =
  "documents.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_CREATE =
  "documents.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_UPDATE =
  "documents.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_DELETE =
  "documents.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_SOFT_DELETE =
  "documents.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_RESTORE =
  "documents.restore";

// customersDocuments
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DOCUMENTS_VIEW_ANY =
  "users.customers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DOCUMENTS_CREATE =
  "users.customers.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DOCUMENTS_UPDATE =
  "users.customers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DOCUMENTS_DELETE =
  "users.customers.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DOCUMENTS_SOFT_DELETE =
  "users.customers.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DOCUMENTS_RESTORE =
  "users.customers.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_DOCUMENTS_DOWNLOAD =
  "users.customers.download";

// subscriptions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW =
  "subscriptions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY =
  "subscriptions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CREATE =
  "subscriptions.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_UPDATE =
  "subscriptions.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_DELETE =
  "subscriptions.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_SOFT_DELETE =
  "subscriptions.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_RESTORE =
  "subscriptions.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_SUBSCRIBE =
  "subscriptions.subscribe";

// subscriptions/customers
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_VIEW_ANY =
  "subscriptions.customers.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_UPDATE =
  "subscriptions.customers.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_ACTIVATE =
  "subscriptions.customers.activate";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_CANCEL =
  "subscriptions.customers.cancel";

// prestations
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_VIEW =
  "prestations.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_VIEW_ANY =
  "prestations.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CREATE =
  "prestations.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_UPDATE =
  "prestations.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_DELETE =
  "prestations.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_SOFT_DELETE =
  "prestations.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_RESTORE =
  "prestations.restore";

// prestations/categories
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_VIEW =
  "prestations.categories.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_VIEW_ANY =
  "prestations.categories.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_CREATE =
  "prestations.categories.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_UPDATE =
  "prestations.categories.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_DELETE =
  "prestations.categories.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_SOFT_DELETE =
  "prestations.categories.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_RESTORE =
  "prestations.categories.restore";

// prestations/groups
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_VIEW =
  "prestations.groups.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_VIEW_ANY =
  "prestations.groups.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_CREATE =
  "prestations.groups.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_UPDATE =
  "prestations.groups.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_DELETE =
  "prestations.groups.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_SOFT_DELETE =
  "prestations.groups.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_RESTORE =
  "prestations.groups.restore";

// sessions
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_VIEW = "sessions.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_VIEW_ANY =
  "sessions.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_CREATE =
  "sessions.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_UPDATE =
  "sessions.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_DELETE =
  "sessions.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_SOFT_DELETE =
  "sessions.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_RESTORE =
  "sessions.restore";

// sessions/registrations
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_VIEW =
  "sessions.registrations.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_VIEW_ANY =
  "sessions.registrations.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_CREATE =
  "sessions.registrations.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_UPDATE =
  "sessions.registrations.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_UPDATE_VALIDATION_ACCEPT =
  "sessions.registrations.update_validation_accept";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_UPDATE_VALIDATION_REFUSE =
  "sessions.registrations.update_validation_refuse";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DELETE =
  "sessions.registrations.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_SOFT_DELETE =
  "sessions.registrations.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_RESTORE =
  "sessions.registrations.restore";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_BATCH =
  "sessions.registrations.batch";

// payments
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_VIEW_ANY =
  "payments.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_VIEW = "payments.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_CREATE =
  "payments.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_UPDATE =
  "payments.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_PAY = "payments.pay";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_CANCEL =
  "payments.cancel";

// sessions/registrations/dates
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_VIEW_ANY =
  "sessions.registrations.dates.view_any";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_CALENDAR =
  "sessions.registrations.dates.calendar";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_SUBSCRIBED =
  "sessions.registrations.dates.subscribed";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_VIEW =
  "sessions.registrations.dates.view";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_CREATE =
  "sessions.registrations.dates.create";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE =
  "sessions.registrations.dates.update";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_PRESENCE_PRESENT =
  "sessions.registrations.dates.update_presence_present";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_PRESENCE_ABSENT =
  "sessions.registrations.dates.update_presence_absent";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_VALIDATION_ACCEPT =
  "sessions.registrations.dates.update_validation_accept";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_VALIDATION_REFUSE =
  "sessions.registrations.dates.update_validation_refuse";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_STATE_CANCELLED =
  "sessions.registrations.dates.update_state_cancelled";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_ARRIVAL =
  "sessions.registrations.dates.update_arrival";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_DEPARTURE =
  "sessions.registrations.dates.update_departure";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_BATCH =
  "sessions.registrations.dates.batch";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_EXPORT =
  "sessions.registrations.dates.export";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_DELETE =
  "sessions.registrations.dates.delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_SOFT_DELETE =
  "sessions.registrations.dates.soft_delete";
export const AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_RESTORE =
  "sessions.registrations.dates.restore";
