// deps
import cookie from "cookie";

// libraries
import whitelist from "@splitfire-agency/raiden-library/dist/libraries/utils/whitelist";

// constants
import browser from "../../../constants/browser";

/**
 * Retourne les cookies au format objet peu.
 * Fonctionne côté client et côté serveur.
 * @param {object} param0
 * @param {import("http").IncomingMessage} [param0.req]
 * @param {object} [param0.whitelist]
 */
export default async function nextGetCookies({
  req,
  whitelist: whitelistCookieNames = {},
}) {
  const cookies = cookie.parse(
    browser ? document.cookie : req?.headers?.cookie ?? "",
  );

  const whitelistedCookieNames = whitelist(
    Object.keys(cookies),
    whitelistCookieNames,
  );

  return {
    unSecureCookies: whitelistedCookieNames.reduce(function (
      unSecureCookies,
      whitelistCookieName,
    ) {
      unSecureCookies[whitelistCookieName] = cookies[whitelistCookieName];

      return unSecureCookies;
    },
    {}),
    cookies,
  };
}
