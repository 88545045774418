import { Box, DarkMode, HStack, Icon, Text } from "@chakra-ui/react";
import ConditionalWrapper from "@splitfire-agency/raiden-library/dist/components/ConditionalWrapper";
import { AnimatePresence, motion } from "framer-motion";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { IoChevronDown } from "react-icons/io5";

const ANIMATION = {
  visible: {
    opacity: 1,
    height: "auto",
  },
  hidden: {
    opacity: 0,
    height: 0,
  },
};

/**
 * @typedef {(params: {
 * pathWithoutQueryParams: string,
 * router: import("next/router").NextRouter,
 * }) => boolean} isActiveFunction
 */

/**
 * Determines if a path is active or not.
 *
 * Supports active deep detection if `children` is provided.
 * @param {object} param0
 * @param {isActiveFunction} [param0.isActive]
 * @param {import("next/router").NextRouter} param0.router
 * @param {string} param0.pathWithoutQueryParams
 * @param {string} [param0.href]
 * @param {Item[]} [param0.children]
 * @returns {boolean}
 */
export function isActivePath({
  isActive,
  router,
  pathWithoutQueryParams,
  href,
  children = [],
}) {
  if (href && href.length > 0) {
    // use custom activation function if provided
    if (isActive) {
      if (isActive({ pathWithoutQueryParams, router })) {
        return true;
      }
    } else {
      if (pathWithoutQueryParams?.startsWith?.(href.split(/[?#]/)[0])) {
        return true;
      }
    }
  }
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (
      isActivePath({
        isActive: child.isActive,
        router,
        pathWithoutQueryParams,
        href: child.href,
        children: child.children,
      })
    ) {
      return true;
    }
  }
  return false;
}

/**
 * @typedef {object} ItemValidatorParams
 * @property {import("@raiden/library/types/User").UserAdmin} params.user
 * @property {boolean} params.isRoot
 * @property {(params: { authorizations: string | string[]}) => boolean} params.canPerform
 */

/**
 * @callback ItemValidator
 * @param {ItemValidatorParams} validatorParams
 *
 * @return {boolean}
 */

/**
 * @typedef {{
 * title: import("react").ReactNode,
 * icon?: import("react-icons").IconType,
 * href?: string,
 * onClick?: () => void,
 * validator?: ItemValidator,
 * depth?: number,
 * isActive?: isActiveFunction,
 * children?: ItemDeclaration[],
 * }} ItemDeclaration
 */

/**
 * @typedef {{
 * id: string,
 * title: import("react").ReactNode,
 * icon?: import("react-icons").IconType,
 * href?: string,
 * onClick?: () => void,
 * validator?: ItemValidator,
 * depth?: number,
 * isActive?: isActiveFunction,
 * children?: Item[],
 * }} Item
 */

/**
 * @param {{
 * item: Item,
 * openedMenuItemsIds: string[],
 * openMenuItem: (Item) => void,
 * closeMenuItem: (Item) => void,
 * }} props
 */
function MenuItem({ item, openedMenuItemsIds, openMenuItem, closeMenuItem }) {
  const router = useRouter();

  const { isActive, isActiveDeep } = useMemo(() => {
    const pathWithoutQueryParams = router.asPath?.split?.(/[?#]/)[0];
    return {
      isActive: isActivePath({
        isActive: item.isActive,
        router,
        pathWithoutQueryParams,
        href: item.href,
      }),
      isActiveDeep: isActivePath({
        isActive: item.isActive,
        router,
        pathWithoutQueryParams,
        href: item.href,
        children: item.children,
      }),
    };
  }, [item.isActive, item.href, item.children, router]);

  const isOpened = openedMenuItemsIds?.includes?.(item.id);

  const handleClickChevron = useCallback(() => {
    isOpened ? closeMenuItem(item) : openMenuItem(item);
  }, [closeMenuItem, isOpened, openMenuItem, item]);

  return (
    <DarkMode>
      <Box role="group">
        <HStack flexShrink={0} spacing="0" position="relative" role="menuitem">
          <Box
            display={isActive ? "block" : "none"}
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            w="4px"
            backgroundColor="brandSecondary.300"
            pointerEvents="none"
          />

          <ConditionalWrapper
            hasWrapper={Boolean(item.href && item.href.length > 0)}
            wrapper={(children) => (
              <NextLink href={item.href ?? ""} passHref>
                {children}
              </NextLink>
            )}>
            <Box
              flexGrow={1}
              as={item.href ? "a" : "button"}
              // @ts-ignore
              onClick={
                item.onClick ??
                (item.children && item.children.length > 0 && !item.href
                  ? handleClickChevron
                  : null)
              }
              pl={`${40 * (item.depth ?? 0)}px`}
              aria-expanded={
                item.children && item.children.length > 0 ? isOpened : null
              }>
              <HStack
                spacing="16px"
                width="full"
                pl="16px"
                pr={item.children ? 0 : "1rem"}
                py="8px">
                {item.icon && (
                  <Text
                    width="24px"
                    height="24px"
                    colorScheme={isActiveDeep ? "brandSecondary" : undefined}
                    _groupHover={{
                      color: "brandSecondary.300",
                    }}>
                    <Icon as={item.icon} boxSize="24px" />
                  </Text>
                )}

                <Text
                  as="div"
                  colorScheme={isActiveDeep ? "brandSecondary" : undefined}
                  _groupHover={{
                    color: "brandSecondary.300",
                  }}>
                  {item.title}
                </Text>
              </HStack>
            </Box>
          </ConditionalWrapper>

          {item.children && item.children.length > 0 && (
            <Box
              as="button"
              onClick={handleClickChevron}
              px="1rem"
              py=".5rem"
              aria-expanded={isOpened}>
              <Icon
                as={IoChevronDown}
                verticalAlign="middle"
                boxSize="24px"
                color="whiteAlpha.800"
                transform={`rotate(${isOpened ? 180 : 0}deg)`}
                transition="transform 0.25s ease-in-out"
                _hover={{ color: "brandSecondary.500" }}
              />
            </Box>
          )}
        </HStack>
      </Box>

      <AnimatePresence initial={false}>
        {item.children && item.children.length > 0 && isOpened && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={ANIMATION}
            style={{ flexShrink: 0, overflow: "hidden" }}>
            <Box backgroundColor="blackAlpha.400">
              {item.children?.map?.((item) => (
                <MenuItem
                  item={item}
                  openedMenuItemsIds={openedMenuItemsIds}
                  openMenuItem={openMenuItem}
                  closeMenuItem={closeMenuItem}
                  key={item.id}
                />
              ))}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </DarkMode>
  );
}

export default MenuItem;
