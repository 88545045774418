/**
 * Retourne l’ensemble des messages de traduction de la langue demandée.
 * @param {string} locale
 * @param {string} defaultLocale
 */
export default async function getTranslationMessages(locale, defaultLocale) {
  const asyncTranslationMessages = import(
    `../../intl/compiled-messages/${locale}.json`
  );

  const translationMessages = await asyncTranslationMessages
    .catch(function () {
      // Si une erreur survient pendant l’import, on charge la langue par défaut.
      return import(`../../intl/compiled-messages/${defaultLocale}.json`);
    })
    .catch(function () {
      throw new ReferenceError(
        "Une erreur est survenue au moment de l’importation de la langue par défaut.",
      );
    })
    .then(function ({ default: translationMessages }) {
      return translationMessages;
    });

  return translationMessages;
}
