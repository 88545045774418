// deps
import { useIntl } from "react-intl";
import Head from "next/head";

// components
import Splash from "@raiden/library/components/Splash";
import PagePanel from "../../components/PagePanel";
import { Box, Button, VStack } from "@chakra-ui/react";

export default function TooManyRequest(props) {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: "raiden.admin.containers.TooManyRequest.header.title",
            defaultMessage: "Patientez un instant",
          })}
        </title>
      </Head>

      <PagePanel.Viewport>
        <VStack spacing="1rem">
          <Splash
            title={intl.formatMessage({
              id: "raiden.admin.containers.TooManyRequest.texts.title",
              defaultMessage: "Plus vite que la lumière…",
            })}
            description={intl.formatMessage({
              id: "raiden.admin.containers.TooManyRequest.texts.description",
              defaultMessage:
                "Nous sommes désolés, mais à moins que votre ordinateur soit alimenté par des croquettes quantiques, nous n’avons pas la capacité à traiter autant de demandes en si peu de temps. Merci d’attendre quelques instants et de recharger la page.",
            })}
            image="error"
          />

          <Box>
            <Button
              variant="solid"
              onClick={function () {
                window.location.reload();
              }}>
              {intl.formatMessage({
                id: "raiden.admin.containers.TooManyRequest.action.home",
                defaultMessage: "Recharger la page",
              })}
            </Button>
          </Box>
        </VStack>
      </PagePanel.Viewport>
    </>
  );
}
