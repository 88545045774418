// deps
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { Link, Box, useBoolean } from "@chakra-ui/react";
import dayjs from "dayjs";

// components
import { IoHeart } from "@raiden/library/components/Icon";

// package
import pkg from "../../package.json";

function Heart(props) {
  const $pulse = useRef(/** @type {HTMLDivElement | null} */ (null));

  const [pulsating, { toggle: togglePulsating }] = useBoolean(false);

  useEffect(
    function () {
      if ($pulse.current instanceof HTMLElement) {
        if (pulsating) {
          $pulse.current.style.visibility = "visible";

          let animationStep = 0;

          const interval = setInterval(function () {
            const scale =
              0 === animationStep || 2 === animationStep ? 1.1 : 0.9;

            if ($pulse.current) {
              $pulse.current.style.transform = `translate3d(-50%, -50%, 0) scale(${scale})`;
            }

            animationStep += 1;
            animationStep %= 8;
          }, 200);

          return function () {
            clearInterval(interval);
          };
        } else {
          $pulse.current.style.visibility = "hidden";
        }
      }
    },
    [pulsating],
  );

  return (
    <Box
      cursor={pulsating ? "grabbing" : "grab"}
      userSelect="none"
      pos="relative"
      d="inline"
      color="red"
      onClick={togglePulsating}>
      <IoHeart />

      <Box
        transition="transform 160ms linear 0ms"
        pos="absolute"
        top="50%"
        left="50%"
        ref={$pulse}>
        <IoHeart />
      </Box>
    </Box>
  );
}

export default function Copyright(props) {
  const intl = useIntl();

  return (
    <Box color="gray.500">
      {intl.formatMessage(
        {
          id: "raiden.admin.components.Copyright.texts.copyright",
          defaultMessage:
            "© {year} {product} v{version} • Fait avec {heart} par <link>Splitfire</link>",
        },
        {
          year: dayjs().year(),
          product: pkg.displayName,
          version: pkg.version,
          link(chunks) {
            return (
              <Link target="_blank" key="link" href="https://www.splitfire.fr">
                {chunks}
              </Link>
            );
          },
          heart: <Heart />,
        },
      )}
    </Box>
  );
}
