// deps
import PropTypes from "prop-types";
import { forwardRef } from "react";

// components
import EnvironmentSelect from "../EnvironmentSelect";

// context
import { useGuards } from "../../../contexts/Guards";
import { usePreferences } from "../../../contexts/Preferences";

// hooks
import useCanPerform from "../../../hooks/useCanPerform";

/**
 * Filtre les environnements en fonction d'une authorisation
 * @param {object} options
 * @param {import("@raiden/library/types/Environment").Environment[]} options.environments
 * @param {string} options.authorization
 * @param {Function} options.canPerform
 * @return {array}
 */
function getListEnvironmentRelayOnAuthorizations({
  environments,
  authorization,
  canPerform,
}) {
  if (authorization) {
    return environments.filter((environment) =>
      canPerform({
        environmentId: environment.id,
        authorizations: authorization,
      }),
    );
  }
  return environments;
}

const EnvironmentSelectWithAuthorizations = forwardRef(
  /**
   * @typedef {Object} Props
   * @property {string} [authorization]
   * @property {boolean} [includeWildcard]
   *
   * @param {import("@chakra-ui/react").SelectProps & Props} props
   */
  function EnvironmentSelectWithAutorizations(props, ref) {
    const { authorization, includeWildcard, ...otherProps } = props;

    const { environments } = usePreferences();

    const canPerform = useCanPerform();
    const { environmentListRelyOnAuthorizations, hasWidlcard } = useGuards();

    const localEnvironments = authorization
      ? getListEnvironmentRelayOnAuthorizations({
          environments,
          authorization: authorization,
          canPerform,
        })
      : environmentListRelyOnAuthorizations;

    if (localEnvironments?.length === 0) {
      return null;
    }

    return (
      <EnvironmentSelect
        ref={ref}
        environments={localEnvironments}
        includeWildcard={includeWildcard && hasWidlcard}
        {...otherProps}
      />
    );
  },
);

EnvironmentSelectWithAuthorizations.propTypes = {
  authorization: PropTypes.string,
  includeWildcard: PropTypes.bool,
};

export default EnvironmentSelectWithAuthorizations;
