import {
  Box,
  DarkMode,
  Divider,
  Flex,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  Portal,
  Spinner,
  Stack,
  Switch,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import useAuthAs from "@raiden/library/hooks/auth/as";
import useCanPerform from "@raiden/library/hooks/useCanPerform";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import UsersAvatar from "../../../components/Users/Avatar";
import { useScrollBlocker } from "../../../hooks/useScrollBlocker";
import UserMenu from "../../UserMenu";
import MenuItem, { isActivePath } from "./MenuItem";
import { useRouter } from "next/router";

/**
 * Trim menu items
 * @param {import("./MenuItem").Item[]} menuItems
 * @param {{
 * user: import("@raiden/library/types/User").UserAdmin,
 * isRoot: boolean,
 * canPerform: (params: { authorizations: string | string[]}) => boolean,
 * }} param1
 * @param {number} [depth]
 * @returns {import("./MenuItem").Item[]}
 */
export function prepare(
  menuItems = [],
  { user, isRoot, canPerform },
  depth = 0,
) {
  const trimmed = [];
  menuItems?.forEach((menuItem) => {
    const trimmedChildren = prepare(menuItem.children, {
      user,
      canPerform,
      isRoot,
    });
    if (
      (menuItem?.validator?.({
        user,
        canPerform,
        isRoot,
      }) ??
        true) &&
      ((menuItem.href && menuItem.href.length > 0) ||
        typeof menuItem?.onClick === "function" ||
        trimmedChildren.length > 0)
    ) {
      trimmed.push({
        ...menuItem,
        children: prepare(
          menuItem.children,
          {
            user,
            canPerform,
            isRoot,
          },
          depth + 1,
        ),
      });
    }
  });
  return trimmed;
}

/**
 * @param {object} options
 * @param {import("next/router").NextRouter} options.router
 * @param {string} options.pathWithoutQueryParams
 * @param {import("./MenuItem").Item[]} options.menuItems
 * @return {string[]}
 */
function getInitialOpenedMenuItemsIds({
  router,
  pathWithoutQueryParams,
  menuItems = [],
}) {
  /** @type {string[]} */
  const openedMenuItemsIds = [];
  menuItems?.forEach((item) => {
    const isActive = isActivePath({
      isActive: item.isActive,
      router,
      pathWithoutQueryParams,
      href: item.href,
    });
    const isActiveDeep = isActivePath({
      isActive: item.isActive,
      router,
      pathWithoutQueryParams,
      href: item.href,
      children: item.children,
    });
    if (
      item.children &&
      item.children.length > 0 &&
      (isActive || isActiveDeep)
    ) {
      openedMenuItemsIds.push(item.id);
    }
  });
  return openedMenuItemsIds;
}

/**
 * @typedef {{
 * menuItems: import("./MenuItem").Item[],
 * isOpened: boolean,
 * onClose: () => void,
 * isMobileLayout: boolean,
 * }} Props
 * @param {import("react").PropsWithChildren<Props>} props
 */
function Menu({ menuItems: _menuItems, isOpened, onClose, isMobileLayout }) {
  const router = useRouter();

  const canPerform = useCanPerform();

  const { block, unblock } = useScrollBlocker();

  const { user, root: isRoot, loading } = useAuthAs();

  const { colorMode, setColorMode } = useColorMode();

  const handleChangeTheme = useCallback(
    function (event) {
      setColorMode(event.target.checked ? "dark" : "light");
    },
    [setColorMode],
  );

  const menuItems = useMemo(() => {
    // @ts-ignore
    return prepare(_menuItems, { user, isRoot, canPerform });
  }, [_menuItems, canPerform, isRoot, user]);

  const [openedMenuItemsIds, setOpenedMenuItemsIds] = useState(() => {
    const pathWithoutQueryParams = router.asPath?.split?.(/[?#]/)[0];
    return getInitialOpenedMenuItemsIds({
      menuItems,
      router,
      pathWithoutQueryParams,
    });
  });

  const openMenuItem = useCallback(
    /** @param {import("./MenuItem").Item} item */
    (item) => {
      if (item.depth === 0) {
        setOpenedMenuItemsIds([item.id]);
      } else {
        setOpenedMenuItemsIds((openedMenuItems) => [
          ...openedMenuItems,
          item.id,
        ]);
      }
    },
    [],
  );

  const closeMenuItem = useCallback(
    /** @param {import("./MenuItem").Item} item */
    (item) => {
      setOpenedMenuItemsIds((openedMenuItems) => {
        const newOpenedMenuItems = [...openedMenuItems];
        const index = newOpenedMenuItems.indexOf(item.id);
        if (index > -1) {
          newOpenedMenuItems.splice(index, 1);
        }
        return newOpenedMenuItems;
      });
    },
    [],
  );

  useEffect(() => {
    if (isOpened && isMobileLayout) {
      block();
    } else {
      unblock();
    }
  }, [block, isMobileLayout, isOpened, unblock]);

  return (
    <DarkMode>
      <Flex
        position="fixed"
        top="4rem"
        left="0"
        bottom="0"
        right="0"
        pointerEvents="none">
        {/* backdrop */}

        <Box
          pointerEvents={isOpened && isMobileLayout ? "all" : "none"}
          position="absolute"
          top="0"
          left="0"
          bottom="0"
          right="0"
          backgroundColor="rgba(0, 0, 0, 0.25)"
          opacity={isOpened && isMobileLayout ? 1 : 0}
          transition="opacity 0.2s"
          onClick={onClose}
        />

        <Stack
          pointerEvents="all"
          w="15rem"
          overflowY="auto"
          spacing="0"
          backgroundColor="gray.700"
          transform={{
            base: isOpened ? "translateX(0)" : "translateX(-100%)",
            lg: "translateX(0)",
          }}
          transition="all 0.25s ease-in-out"
          boxShadow={{
            base: isOpened
              ? "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"
              : "none",
            lg: "none",
          }}
          role="menubar">
          {isMobileLayout && (
            <>
              <Box px="16px" py="8px">
                <Switch
                  size="sm"
                  id="menu-theme-switcher"
                  isChecked={"dark" === colorMode}
                  onChange={handleChangeTheme}>
                  <Text as="span">
                    <FormattedMessage
                      defaultMessage="{colorMode, select, dark {Thème sombre} other {Thème clair}}"
                      values={{ colorMode }}
                    />
                  </Text>
                </Switch>
              </Box>

              <ChakraMenu placement={"bottom"} isLazy={true}>
                <ChakraMenuButton>
                  <Box px="16px" py="8px">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <UsersAvatar size="xs" user={user} />
                    )}
                  </Box>
                </ChakraMenuButton>

                <Portal>
                  <UserMenu />
                </Portal>
              </ChakraMenu>

              <Divider />
            </>
          )}

          {menuItems?.map?.((item) => (
            <MenuItem
              item={item}
              openedMenuItemsIds={openedMenuItemsIds}
              openMenuItem={openMenuItem}
              closeMenuItem={closeMenuItem}
              key={item.id}
            />
          ))}
        </Stack>
      </Flex>
    </DarkMode>
  );
}

export default Menu;
