// deps
// eslint-disable-next-line import/no-unresolved
import Router from "@splitfire-agency/raiden-library/dist/libraries/RouterImplementation/Router";
// eslint-disable-next-line import/no-unresolved
import Uri from "@splitfire-agency/raiden-library/dist/libraries/Uri";

const apiRouter = new Router();

apiRouter.group(
  // @ts-ignore
  new Uri(
    process.env.NEXT_PUBLIC_API_SCHEME,
    null,
    process.env.NEXT_PUBLIC_API_HOST,
    null,
    process.env.NEXT_PUBLIC_API_BASE_PATH,
  ),
  (apiRouter) => {
    apiRouter.group("/api", (apiRouter) => {
      // Configuration
      apiRouter.get("/configuration").setId("@configuration");

      // Environments
      apiRouter.group("/environments", (apiRouter) => {
        apiRouter.get("").setId("@environments.search");
        apiRouter.post("").setId("@environments.create");
        apiRouter.group("/<environmentId>", function (apiRouter) {
          apiRouter.setConstraint("environmentId", /\d+/);
          apiRouter.get("/quotas").setId("@environments.quotas");
          apiRouter.get("").setId("@environments.show");
          apiRouter.post("").setId("@environments.edit");
          apiRouter.delete("").setId("@environments.delete");
          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@environments.restore");
          });
        });
      });

      // Users
      apiRouter.group("/users", (apiRouter) => {
        // Users/Groups
        apiRouter.group("/groups", (apiRouter) => {
          apiRouter.get("").setId("@usersGroups.search");

          apiRouter.post("").setId("@usersGroups.create");

          apiRouter.group("/<usersGroupId>", (apiRouter) => {
            apiRouter.setConstraint("usersGroupId", /\d+/);

            apiRouter.get("").setId("@usersGroups.show");

            apiRouter.post("").setId("@usersGroups.edit");

            apiRouter.delete("").setId("@usersGroups.delete");
          });
        });

        // Users/Admins
        apiRouter.group("/admins", (apiRouter) => {
          apiRouter.get("").setId("@usersAdmins.search");

          apiRouter.post("").setId("@usersAdmins.create");

          apiRouter.group("/<usersAdminId>", (apiRouter) => {
            apiRouter.setConstraint("usersAdminId", /\d+/);

            apiRouter.get("").setId("@usersAdmins.show");

            apiRouter.post("").setId("@usersAdmins.edit");

            apiRouter.delete("").setId("@usersAdmins.delete");
          });
        });

        // Users/PasswordResets
        apiRouter.group("/password-resets", (apiRouter) => {
          apiRouter.post("").setId("@usersPasswordResets.create");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter
              .post("/finish")
              .setId("@usersPasswordResets.actionFinish");
          });
        });

        // Users/Customers
        apiRouter.group("/customers", function (apiRouter) {
          apiRouter.get("").setId("@customers.search");
          apiRouter.post("").setId("@customers.create");
          apiRouter.group("/<customerId>", function (apiRouter) {
            apiRouter.setConstraint("customerId", /\d+/);
            apiRouter.get("").setId("@customers.view");
            apiRouter.post("").setId("@customers.update");
            apiRouter.delete("").setId("@customers.delete");
            apiRouter.group("/action", function (apiRouter) {
              apiRouter.post("/restore").setId("@customers.restore");
              apiRouter.post("/validate").setId("@customers.validate");
              apiRouter.post("/invalidate").setId("@customers.invalidate");
              apiRouter
                .post("/download-justificative")
                .setId("@customers.justificative");
              apiRouter
                .post("/verification-notification")
                .setId("@customers.verificationNotification");
            });
          });
          apiRouter.group("/documents", function (apiRouter) {
            apiRouter.get("").setId("@customers.documents.search");
            apiRouter.post("").setId("@customers.documents.create");
            apiRouter.group("/<documentId>", function (apiRouter) {
              apiRouter.setConstraint("documentId", /\d+/);
              apiRouter.get("").setId("@customers.documents.view");
              apiRouter.post("").setId("@customers.documents.update");
              apiRouter.delete("").setId("@customers.documents.delete");
              apiRouter.group("/action", function (apiRouter) {
                apiRouter
                  .post("/download")
                  .setId("@customers.documents.download");
                apiRouter
                  .post("/restore")
                  .setId("@customers.documents.restore");
              });
              apiRouter
                .post("/pages")
                .setId("@customers.documents.pages.create");
            });
            apiRouter.group("/pages", function (apiRouter) {
              apiRouter.group("/<pageId>", function (apiRouter) {
                apiRouter
                  .get("/action/download")
                  .setId("@customers.documents.pages.download");
                apiRouter.delete("").setId("@customers.documents.pages.delete");
              });
            });
          });
        });

        // Users/Addresses
        apiRouter.group("/addresses", function (apiRouter) {
          apiRouter.get("").setId("@addresses.search");
          apiRouter.post("").setId("@addresses.create");
          apiRouter.group("/<addressId>", function (apiRouter) {
            apiRouter.setConstraint("addressId", /\d+/);
            apiRouter.post("").setId("@addresses.update");
            apiRouter.delete("").setId("@addresses.delete");
            apiRouter.group("/action", function (apiRouter) {
              apiRouter.post("/restore").setId("@addresses.restore");
            });
          });
        });

        // Users/2FA
        apiRouter.group("/two-factor-authentication", function (apiRouter) {
          apiRouter.get("").setId("@2fa.view");
          apiRouter.delete("").setId("@2fa.delete");
          apiRouter.post("").setId("@2fa.enable");
          apiRouter.post("/enrollment").setId("@2fa.enrollment");
          apiRouter.post("/challenge").setId("@2fa.challenge");
        });
      });

      // Posts
      apiRouter.group("/posts", (apiRouter) => {
        apiRouter.get("").setId("@posts.search");

        apiRouter.post("").setId("@posts.create");

        apiRouter.group("/<postId>", (apiRouter) => {
          apiRouter.setConstraint("postId", /\d+/);

          apiRouter.get("").setId("@posts.show");

          apiRouter.post("").setId("@posts.edit");

          apiRouter.delete("").setId("@posts.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@posts.restore");
          });
        });

        // Posts/Categories
        apiRouter.group("/categories", (apiRouter) => {
          apiRouter.get("").setId("@postsCategories.search");

          apiRouter.get("/tree").setId("@postsCategories.treeView");

          apiRouter.post("/tree").setId("@postsCategories.treeUpdate");

          apiRouter.post("").setId("@postsCategories.create");

          apiRouter.group("/<postsCategoryId>", (apiRouter) => {
            apiRouter.setConstraint("postsCategoryId", /\d+/);

            apiRouter.get("").setId("@postsCategories.show");

            apiRouter.post("").setId("@postsCategories.edit");

            apiRouter.post("").setId("@postsCategories.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter.group("/restore", (apiRouter) => {
                apiRouter.post("").setId("@postsCategories.restore");
              });
            });
          });
        });

        apiRouter.group("/revisions", (apiRouter) => {
          apiRouter.group("/<postsRevisionId>", (apiRouter) => {
            apiRouter.setConstraint("postsRevisionId", /\d+/);

            apiRouter.post("").setId("@postsRevisions.edit");

            apiRouter.delete("").setId("@postsRevisions.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter.post("/review").setId("@postsRevisions.review");

              apiRouter.post("/restore").setId("@postsRevisions.restore");
            });
          });
        });
      });

      // Covers
      apiRouter.group("/covers", (apiRouter) => {
        apiRouter.get("").setId("@covers.viewAny");

        apiRouter.post("").setId("@covers.create");

        apiRouter.group("/<coverId>", (apiRouter) => {
          apiRouter.setConstraint("coverId", /\d+/);

          apiRouter.post("").setId("@covers.edit");

          apiRouter.delete("").setId("@covers.delete");
        });
      });

      // Files
      apiRouter.group("/files", (apiRouter) => {
        apiRouter.get("").setId("@files.viewAny");

        apiRouter.post("").setId("@files.create");

        apiRouter.group("/<fileId>", (apiRouter) => {
          apiRouter.setConstraint("fileId", /\d+/);

          apiRouter.delete("").setId("@files.delete");

          apiRouter.get("").setId("@files.view");

          apiRouter.post("").setId("@files.update");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@files.restore");
          });
        });
      });

      // Countries
      apiRouter.group("/countries", (apiRouter) => {
        apiRouter.get("").setId("@countries.viewAny");
        apiRouter.post("").setId("@countries.create");

        apiRouter.group("/<countryId>", (apiRouter) => {
          apiRouter.setConstraint("countryId", /\d+/);
          apiRouter.get("").setId("@countries.show");
          apiRouter.post("").setId("@countries.edit");
          apiRouter.post("").setId("@countries.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@countries.restore");
          });
        });
      });

      // Menus
      apiRouter.group("/menus", (apiRouter) => {
        apiRouter.get("").setId("@menus.viewAny");
        apiRouter.post("").setId("@menus.create");

        apiRouter.group("/<menuId>", (apiRouter) => {
          apiRouter.setConstraint("menuId", /\d+/);
          apiRouter.get("").setId("@menus.view");
          apiRouter.post("").setId("@menus.update");
          apiRouter.post("").setId("@menus.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@menus.restore");
          });
        });
      });

      // invoices
      apiRouter.group("/invoices", (apiRouter) => {
        apiRouter.get("").setId("@invoices.viewAny");
        apiRouter.post("").setId("@invoices.create");

        // invoices/{invoiceId}
        apiRouter.group("/<invoiceId>", (apiRouter) => {
          apiRouter.setConstraint("invoiceId", /\d+/);
          apiRouter.get("").setId("@invoices.view");
          apiRouter.post("").setId("@invoices.update");

          // invoices/{invoiceId}/action
          apiRouter.group("/action", (apiRouter) => {
            apiRouter.get("/download").setId("@invoices.download");
          });
        });

        // invoices/{invoiceId}/action
        apiRouter.group("/action", (apiRouter) => {
          apiRouter.get("/export").setId("@invoices.export");
        });
      });

      // accounts
      apiRouter.group("/accounts", (apiRouter) => {
        // accounts/records
        apiRouter.group("/records", (apiRouter) => {
          // accounts/records/exports
          apiRouter.group("/exports", (apiRouter) => {
            apiRouter.get("").setId("@accountsRecordsExports.viewAny");
            // accounts/records/exports/{exportId}
            apiRouter.group("/<exportId>", (apiRouter) => {
              apiRouter.setConstraint("exportId", /\d+/);
              // accounts/records/exports/{exportId}/action
              apiRouter.group("/action", (apiRouter) => {
                apiRouter
                  .get("/download")
                  .setId("@accountsRecordsExports.download");
              });
            });
          });
        });
      });

      // Carriers
      apiRouter.group("/carriers", function (apiRouter) {
        apiRouter.get("").setId("@carriers.search");
        apiRouter.post("").setId("@carriers.create");

        apiRouter.group("/<carrierId>", function (apiRouter) {
          apiRouter.setConstraint("carrierId", /\d+/);
          apiRouter.get("").setId("@carriers.show");
          apiRouter.post("").setId("@carriers.edit");
          apiRouter.post("").setId("@carriers.delete");

          apiRouter.group("/action", function (apiRouter) {
            apiRouter.post("/restore").setId("@carriers.restore");
            apiRouter.post("/associate").setId("@carriers.associate");
            apiRouter.post("/dissociate").setId("@carriers.dissociate");
          });
        });
      });

      // classrooms
      apiRouter.group("/classrooms", (apiRouter) => {
        apiRouter.get("").setId("@classrooms.viewAny");
        apiRouter.post("").setId("@classrooms.create");

        apiRouter.group("/<classroomId>", (apiRouter) => {
          apiRouter.get("").setId("@classrooms.view");
          apiRouter.post("").setId("@classrooms.update");
          apiRouter.delete("").setId("@classrooms.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@classrooms.restore");
          });
        });
      });

      // places
      apiRouter.group("/places", (apiRouter) => {
        apiRouter.get("").setId("@places.viewAny");
        apiRouter.post("").setId("@places.create");

        apiRouter.group("/<placeId>", (apiRouter) => {
          apiRouter.get("").setId("@places.view");
          apiRouter.post("").setId("@places.update");
          apiRouter.delete("").setId("@places.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@places.restore");
          });

          apiRouter.group("/settings", (apiRouter) => {
            apiRouter.get("").setId("@places.settings.viewAny");
            apiRouter.post("").setId("@places.settings.create");

            apiRouter.group("/<settingId>", (apiRouter) => {
              apiRouter.post("").setId("@places.settings.update");
              apiRouter.post("").setId("@places.settings.delete");
            });
          });
        });
      });

      // years
      apiRouter.group("/years", (apiRouter) => {
        apiRouter.get("").setId("@years.viewAny");
        apiRouter.post("").setId("@years.create");

        apiRouter.group("/<yearId>", (apiRouter) => {
          apiRouter.get("").setId("@years.view");
          apiRouter.post("").setId("@years.update");
          apiRouter.delete("").setId("@years.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@years.restore");
          });
        });
      });

      // holders
      apiRouter.group("/holders", (apiRouter) => {
        apiRouter.get("").setId("@holders.viewAny");
        apiRouter.post("").setId("@holders.create");

        apiRouter.group("/<holderId>", (apiRouter) => {
          apiRouter.get("").setId("@holders.view");
          apiRouter.post("").setId("@holders.update");
          apiRouter.delete("").setId("@holders.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@holders.restore");
            apiRouter.post("/associate").setId("@holders.associate");
            apiRouter.post("/dissociate").setId("@holders.dissociate");
            apiRouter.post("/validate").setId("@holders.validate");
            apiRouter.post("/invalidate").setId("@holders.invalidate");
            apiRouter.post("/batch").setId("@holders.batch");
            apiRouter.post("/export").setId("@holders.export");

            apiRouter.group("/schoolings", (apiRouter) => {
              apiRouter.post("/").setId("@holders.schooling.upsert");
              apiRouter.delete("/").setId("@holders.schooling.delete");
            });
          });
        });
      });

      // documents
      apiRouter.group("/documents", (apiRouter) => {
        apiRouter.get("").setId("@documents.viewAny");
        apiRouter.post("").setId("@documents.create");

        apiRouter.group("/<documentId>", (apiRouter) => {
          apiRouter.get("").setId("@documents.view");
          apiRouter.post("").setId("@documents.update");
          apiRouter.delete("").setId("@documents.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@documents.restore");
            apiRouter.post("/associate").setId("@documents.associate");
            apiRouter.post("/dissociate").setId("@documents.dissociate");
            apiRouter.post("/download").setId("@documents.download");
          });
        });
      });

      // subscriptions
      apiRouter.group("/subscriptions", (apiRouter) => {
        apiRouter.get("").setId("@subscriptions.viewAny");
        apiRouter.post("").setId("@subscriptions.create");

        apiRouter.group("/<subscriptionId>", (apiRouter) => {
          apiRouter.get("").setId("@subscriptions.view");
          apiRouter.post("").setId("@subscriptions.update");
          apiRouter.delete("").setId("@subscriptions.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@subscriptions.restore");
            apiRouter.post("/subscribe").setId("@subscriptions.subscribe");
          });
        });

        // subscriptions/customers
        apiRouter.group("/customers", (apiRouter) => {
          apiRouter.get("").setId("@subscriptions.customers.viewAny");
          apiRouter.group("/<subscriptionCustomerId>", (apiRouter) => {
            apiRouter.post("").setId("@subscriptions.customers.update");
          });

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/batch").setId("@subscriptions.customers.batch");
          });
        });
      });

      // prestations
      apiRouter.group("/prestations", (apiRouter) => {
        apiRouter.get("").setId("@prestations.viewAny");
        apiRouter.post("").setId("@prestations.create");

        apiRouter.group("/<prestationId>", (apiRouter) => {
          apiRouter.get("").setId("@prestations.view");
          apiRouter.post("").setId("@prestations.update");
          apiRouter.delete("").setId("@prestations.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@prestations.restore");
          });
        });

        // prestations/categories
        apiRouter.group("/categories", (apiRouter) => {
          apiRouter.get("").setId("@prestations.categories.viewAny");
          apiRouter.post("").setId("@prestations.categories.create");

          apiRouter.group("/<prestationCategoryId>", (apiRouter) => {
            apiRouter.get("").setId("@prestations.categories.view");
            apiRouter.post("").setId("@prestations.categories.update");
            apiRouter.delete("").setId("@prestations.categories.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter
                .post("/restore")
                .setId("@prestations.categories.restore");
            });
          });
        });

        // prestations/categories
        apiRouter.group("/groups", (apiRouter) => {
          apiRouter.get("").setId("@prestations.groups.viewAny");
          apiRouter.post("").setId("@prestations.groups.create");

          apiRouter.group("/<prestationGroupId>", (apiRouter) => {
            apiRouter.get("").setId("@prestations.groups.view");
            apiRouter.post("").setId("@prestations.groups.update");
            apiRouter.delete("").setId("@prestations.groups.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter.post("/restore").setId("@prestations.groups.restore");
            });
          });
        });
      });

      // payments
      apiRouter.group("/payments", (apiRouter) => {
        apiRouter.get("").setId("@payments.viewAny");
        apiRouter.post("").setId("@payments.create");

        apiRouter.group("/<paymentId>", (apiRouter) => {
          apiRouter.get("").setId("@payments.view");
          apiRouter.post("").setId("@payments.update");
        });

        apiRouter.group("/action", (apiRouter) => {
          apiRouter
            .post("/credit-card-settings")
            .setId("@payments.creditCardSettings");
        });
      });

      // sessions
      apiRouter.group("/sessions", (apiRouter) => {
        apiRouter.get("").setId("@sessions.viewAny");
        apiRouter.post("").setId("@sessions.create");

        apiRouter.group("/<sessionId>", (apiRouter) => {
          apiRouter.get("").setId("@sessions.view");
          apiRouter.post("").setId("@sessions.update");
          apiRouter.delete("").setId("@sessions.delete");

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/restore").setId("@sessions.restore");
          });
        });

        // sessions/registrations
        apiRouter.group("/registrations", (apiRouter) => {
          apiRouter.get("").setId("@sessions.registrations.viewAny");
          apiRouter.post("").setId("@sessions.registrations.create");

          apiRouter.group("/<sessionRegistrationId>", (apiRouter) => {
            apiRouter.get("").setId("@sessions.registrations.view");
            apiRouter.post("").setId("@sessions.registrations.update");
            apiRouter.delete("").setId("@sessions.registrations.delete");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter
                .post("/restore")
                .setId("@sessions.registrations.restore");
              apiRouter.post("/batch").setId("@sessions.registrations.batch");
            });
          });

          apiRouter.group("/action", (apiRouter) => {
            apiRouter.post("/batch").setId("@sessions.registrations.batch");
          });

          // sessions/registrations/dates
          apiRouter.group("/dates", (apiRouter) => {
            apiRouter.get("").setId("@sessions.registrations.dates.viewAny");
            apiRouter.post("").setId("@sessions.registrations.dates.create");

            apiRouter.group("/action", (apiRouter) => {
              apiRouter
                .post("/batch")
                .setId("@sessions.registrations.dates.batch");
              apiRouter
                .get("/subscribed")
                .setId("@sessions.registrations.dates.subscribed");
            });

            apiRouter.group("/<sessionRegistrationDateId>", (apiRouter) => {
              apiRouter.get("").setId("@sessions.registrations.dates.view");
              apiRouter.post("").setId("@sessions.registrations.dates.update");
              apiRouter
                .delete("")
                .setId("@sessions.registrations.dates.delete");

              apiRouter.group("/action", (apiRouter) => {
                apiRouter
                  .post("/restore")
                  .setId("@sessions.registrations.dates.restore");
                apiRouter
                  .post("/export")
                  .setId("@sessions.registrations.dates.export");
              });
            });

            // sessions/registrations/action
            apiRouter.group("/action", (apiRouter) => {
              apiRouter
                .get("/calendar")
                .setId("@sessions.registrations.dates.calendar");
            });

            // sessions/registrations/histories
            apiRouter.group("/histories", (apiRouter) => {
              apiRouter
                .get("/action/export")
                .setId("@sessions.registrations.dates.histories.export");
            });
          });
        });
      });

      // contacts
      apiRouter.group("/contacts", (apiRouter) => {
        apiRouter.post("").setId("@contacts.create");
      });

      // authorizations
      apiRouter.group("/authorizations", (apiRouter) => {
        apiRouter.get("").setId("@authorizations.viewAny");
      });
    });

    apiRouter.group("/auth", (apiRouter) => {
      apiRouter.post("/login").setId("@auth.login");

      apiRouter.post("/me").setId("@auth.me");

      apiRouter.post("/logout").setId("@auth.logout");
    });

    apiRouter.group("/sanctum", (apiRouter) => {
      apiRouter.group("/csrf-cookie", (apiRouter) => {
        apiRouter.get("").setId("@sanctum.csrfCookie");
      });
    });

    apiRouter.group("/laravel", (apiRouter) => {
      apiRouter.get("/telescope").setId("@laravel.telescope");

      apiRouter.get("/horizon").setId("@laravel.horizon");
    });
  },
);

export default apiRouter;
