// deps
import PropTypes from "prop-types";
import { Box } from "@chakra-ui/react";

/**
 * @param {{
 * as?: any,
 * children: import("react").ReactNode,
 * [key:string]: any,
 * }} param0
 */
export default function PagePanelWrapper({
  as: Tag = Box,
  children,
  ...otherProps
}) {
  return (
    <Tag {...otherProps} flexGrow={1}>
      {children}
    </Tag>
  );
}

PagePanelWrapper.propTypes = {
  as: PropTypes.any,
  children: PropTypes.node,
};

PagePanelWrapper.defaultProps = {
  as: Box,
};
