// deps
import cookie from "cookie";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { createContext, memo, useContext, useMemo, useRef } from "react";

// hooks
import { useConfiguration } from "../../hooks/useConfiguration";

// constants
import {
  COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
  COOKIES_NAME_VALUE_PREFER_MENU_SHRINK,
} from "../../constants/cookies";

// libraries
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

/**
 * @typedef {object} PreferencesContextValue
 * @property {boolean} preferMenuShrink
 * @property {(value: boolean) => void} setPreferMenuShrink
 * @property {number} paginationPerPage
 * @property {(value: number) => void} setPaginationPerPage
 * @property {import('../../types/Configuration').ConfigurationEnvironment[]} environments
 * @property {import("../../types/Configuration").ConfigurationEnvironment | null} bestEnvironment
 * @property {boolean} shouldRenderEnvironments
 */

/** @type {PreferencesContextValue} */
const DEFAULT_VALUE = {
  preferMenuShrink: false,
  setPreferMenuShrink: () => {},
  paginationPerPage: 25,
  setPaginationPerPage: () => {},
  environments: [],
  bestEnvironment: null,
  shouldRenderEnvironments: false,
};

export const PreferencesContext = createContext(DEFAULT_VALUE);

/**
 * @typedef {object} props
 * @property {number} [initialPaginationPerPage]
 * @property {boolean} [initialPreferMenuShrink]
 * @property {import("react").ReactNode} children
 *
 * @param {props} props
 */
function _PreferencesProvider(props) {
  const {
    initialPaginationPerPage = 25,
    initialPreferMenuShrink = false,
    children,
  } = props;

  const { configuration } = useConfiguration();

  const environments = useMemo(() => {
    return configuration?.environments ?? [];
  }, [configuration?.environments]);

  const paginationPerPage = useRef(Number(initialPaginationPerPage));
  const preferMenuShrink = useRef(initialPreferMenuShrink);

  const value = useMemo(
    /** @return {PreferencesContextValue} */
    function () {
      return {
        preferMenuShrink: preferMenuShrink.current,
        setPreferMenuShrink(newPreferMenuShrink) {
          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_PREFER_MENU_SHRINK,
            newPreferMenuShrink ? "1" : "0",
            {
              expires: dayjs().add(1, "years").toDate(),
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
              secure: true,
            },
          );
          preferMenuShrink.current = newPreferMenuShrink;
        },
        paginationPerPage: paginationPerPage.current,
        setPaginationPerPage(newPaginationPerPage) {
          document.cookie = cookie.serialize(
            COOKIES_NAME_VALUE_PAGINATION_PER_PAGE,
            String(newPaginationPerPage),
            {
              expires: dayjs().add(1, "years").toDate(),
              path: getBasePath(process.env.NEXT_PUBLIC_ADMIN_BASE_PATH ?? ""),
              secure: true,
            },
          );

          paginationPerPage.current = newPaginationPerPage;
        },
        shouldRenderEnvironments: environments.length > 1,
        bestEnvironment: environments.length === 1 ? environments[0] : null,
        environments,
      };
    },
    [environments],
  );

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  );
}

_PreferencesProvider.displayName = "PreferencesProvider";

_PreferencesProvider.propTypes = {
  initialPreferMenuShrink: PropTypes.bool,
  initialEnvironments: PropTypes.any,
  initialPaginationPerPage: PropTypes.any,
  children: PropTypes.node,
};

export const PreferencesProvider = memo(_PreferencesProvider);

export const usePreferences = function () {
  return useContext(PreferencesContext);
};
