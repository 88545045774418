// constants
import browser from "@splitfire-agency/raiden-library/dist/constants/browser";

// libraries
import UriImmutable from "@splitfire-agency/raiden-library/dist/libraries/UriImmutable";

/**
 * Récupère l’Uri courant.
 * @param {object} params
 * @param {import("http").IncomingMessage} [params.req]
 * @param {string} [params.asPath]
 * @return {UriImmutable}
 */
export default function nextGetUri({ req, asPath }) {
  asPath = asPath?.slice(process.env.NEXT_PUBLIC_FRONT_BASE_PATH?.length ?? 0);

  const asPathSplit = asPath?.split("?");

  if (browser) {
    return new UriImmutable(
      window.location.protocol.slice(0, -1),
      undefined,
      window.location.hostname,
      window.location.port,
      asPathSplit?.[0],
      asPathSplit?.[1],
    );
  }

  const { URL } = require("url");

  const url = new URL(
    req.url,
    `${req.headers["x-forwarded-proto"] ?? req.protocol}://${
      req.headers["x-forwarded-host"] ?? req.headers.host
    }`,
  );

  return new UriImmutable(
    url.protocol.slice(0, -1),
    undefined,
    url.hostname,
    url.port ? url.port : null,
    url.pathname,
    url.search.slice(1),
  );
}
