// deps
import PropTypes from "prop-types";
import { Box, Alert, AlertTitle, AlertDescription } from "@chakra-ui/react";

// components
import SplashImage from "./Image";

export default function Splash(props) {
  const { title, description, image } = props;

  return (
    <Box py="3rem">
      <Box maxW="21.875rem" mx="auto">
        {"string" === typeof image ? <SplashImage image={image} /> : image}
      </Box>

      <Alert
        bg="transparent"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center">
        <AlertTitle mt="1rem" mb="0.5rem" fontSize="lg">
          {title}
        </AlertTitle>

        <AlertDescription maxWidth="sm">{description}</AlertDescription>
      </Alert>
    </Box>
  );
}

Splash.propTypes = {
  title: PropTypes.node,
  description: PropTypes.node,
  image: PropTypes.oneOfType([SplashImage.propTypes.image, PropTypes.node]),
};
